import ProductButton from './ProductButton';
import React from 'react';
import { validateLoginSession, getUserInfo, fetchUserInfo, calcCartTotal } from './utils.js';
import { withRouter } from 'react-router-dom';
import TimeoutModal from './TimeoutModal';
import './Main.css';
import NavBar from './NavBar';
import { CoffeeMornings } from './CoffeeMornings';
import { GreatThings } from './GreatThings';
import { handleDelete, handleMinus, handlePlus } from './cartFunctions';
import Loading from './Loading';
import Bottle from './Bottle';
import bottleImages from './bottle_images.json';

class Main extends React.Component {
  glass_sizes = ['24 x 330 mL', '12 x 750 mL'];
  plastic_sizes = ['30 x 330 mL', '24 x 500 mL', '12 x 750 mL', '12 x 1.5 L'];
  flavors = ['Tahitian Lime', 'Sicilian Lemon', 'Blood Orange', 'Lemongrass'];
  flavored_sizes = ['6 x 500 mL', '24 x 500 mL'];

  still_bottle_types = ['Plastic', 'Glass'];
  sparkling_bottle_types = ['Glass'];
  flavored_bottle_types = ['Plastic'];

  products_prices = {};

  validConfigs = {
    Still: {
      order: ['Plastic', 'Glass'],
      Plastic: {
        Sizes: this.plastic_sizes,
      },
      Glass: {
        Sizes: this.glass_sizes,
      },
    },
    Sparkling: {
      order: ['Glass'],
      Glass: {
        Sizes: this.glass_sizes,
      },
    },
    'Flavored Sparkling': {
      order: ['Plastic'],
      Plastic: {
        Flavors: this.flavors,
        Sizes: this.flavored_sizes,
      },
    },
  };

  constructor(props) {
    // Required step: always call the parent class' constructor
    super(props);

    // Set the state directly. Use props if necessary.
    let cached_cart = localStorage.getItem('cart') ? JSON.parse(localStorage.getItem('cart')) : [];
    let loggedIn = validateLoginSession();

    this.state = {
      // Note: think carefully before initializing
      // state based on props!
      mobile: window.matchMedia('(max-width: 1200px)').matches,
      total: calcCartTotal(cached_cart),
      loggedIn: loggedIn,
      user: loggedIn ? getUserInfo() : {},
      selected: {
        type: 'Still',
        bottle: this.still_bottle_types[0],
        size: this.plastic_sizes[0],
        flavor: null,
        quantity: 1,
      },
      cart: cached_cart,
      stock: {
        'Still Plastic 30 x 330 mL': 10,
        'Still Plastic 24 x 500 mL': 10,
        'Still Plastic 12 x 750 mL': 10,
        'Still Plastic 12 x 1.5 L': 10,

        'Still Glass 24 x 330 mL': 10,
        'Still Glass 12 x 750 mL': 10,
        'Sparkling Glass 24 x 330 mL': 10,
        'Sparkling Glass 12 x 750 mL': 10,

        'Flavored Sparkling Plastic Tahitian Lime 6 x 500 mL': 10,
        'Flavored Sparkling Plastic Sicilian Lemon 6 x 500 mL': 10,
        'Flavored Sparkling Plastic Blood Orange 6 x 500 mL': 10,
        'Flavored Sparkling Plastic Lemongrass 6 x 500 mL': 10,

        'Flavored Sparkling Plastic Tahitian Lime 24 x 500 mL': 10,
        'Flavored Sparkling Plastic Sicilian Lemon 24 x 500 mL': 10,
        'Flavored Sparkling Plastic Blood Orange 24 x 500 mL': 10,
        'Flavored Sparkling Plastic Lemongrass 24 x 500 mL': 10,
      },
    };
    this.handleClick = this.handleClick.bind(this);
    this.add_quantity = this.add_quantity.bind(this);
    this.sub_quantity = this.sub_quantity.bind(this);
    this.add_to_cart = this.add_to_cart.bind(this);
    this.handlePlus = handlePlus.bind(this);
    this.handleMinus = handleMinus.bind(this);
    this.handleDelete = handleDelete.bind(this);
  }

  reconfigure(category, selection, prevState) {
    let selectedType = prevState.selected.type;
    let selectedBottle = prevState.selected.bottle;
    let selectedSize = prevState.selected.size;
    let selectedFlavor = prevState.selected.flavor;

    if (category === 'type') {
      selectedType = selection;
      selectedBottle = this.validConfigs[selection]['order'][0];
      selectedSize = this.validConfigs[selection][selectedBottle]['Sizes'][0];

      selectedFlavor = null;
      if (selection === 'Flavored Sparkling') {
        selectedFlavor = this.validConfigs[selection][selectedBottle]['Flavors'][0];
      }
    } else if (category === 'bottle') {
      // type is the same as prevState
      selectedBottle = selection;
      selectedSize = this.validConfigs[selectedType][selectedBottle]['Sizes'][0];
    } else if (category === 'size') {
      // type and bottle are the same as prevState
      selectedSize = selection;
    } else if (category === 'flavor') {
      selectedFlavor = selection;
      if (selection === 'Lemongrass') {
        selectedSize = this.validConfigs[selectedType][selectedBottle]['Sizes'][1];
      }
    }

    prevState.selected.type = selectedType;
    prevState.selected.bottle = selectedBottle;
    prevState.selected.size = selectedSize;
    prevState.selected.flavor = selectedFlavor;

    return prevState;
  }

  toggleLanguage() {}

  handleClick(category, text) {
    this.setState((prevState, props) => {
      return this.reconfigure(category, text, prevState);
    });
  }

  add_quantity() {
    this.setState((prevState, props) => ({
      selected: { ...prevState.selected, quantity: prevState.selected.quantity + 1 },
    }));
  }

  sub_quantity() {
    this.setState((prevState, props) => ({
      selected: { ...prevState.selected, quantity: Math.max(prevState.selected.quantity - 1, 1) },
    }));
  }

  calc_selected_total(sel) {
    let tot = sel.quantity * this.products_prices[this.get_product_key(sel)];
    return Math.round((tot + Number.EPSILON) * 100) / 100;
  }

  get_product_key(sel) {
    let flavor = '';
    if (sel.flavor !== null) {
      flavor = sel.flavor + ' ';
    }
    return sel.type + ' ' + sel.bottle + ' ' + flavor + sel.size;
  }

  add_to_cart() {
    this.setState((prevState, props) => {
      let sel = prevState.selected;
      console.log(sel);
      // check if item has 0 stock and abort if so
      let key = this.get_product_key(sel);
      if (prevState.stock[key] === 0) {
        return null;
      }

      // check if item is already in cart and update quantity
      let newCart = [];
      let updated = false;
      prevState.cart.forEach((item) => {
        if (sel.type === item.type && sel.bottle === item.bottle && sel.size === item.size && sel.flavor === item.flavor) {
          let copy = {};
          Object.assign(copy, item);
          copy.quantity += sel.quantity;
          updated = true;
          newCart.push(copy);
          return; //doesn't return, continues
        }

        newCart.push(item);
      });
      // else add new item
      if (!updated) {
        newCart.push({
          type: prevState.selected.type,
          bottle: prevState.selected.bottle,
          size: prevState.selected.size,
          flavor: prevState.selected.flavor,
          quantity: prevState.selected.quantity,
          unitPrice: this.products_prices[key],
          key: key,
        });
      }

      localStorage.setItem('cart', JSON.stringify(newCart));

      return {
        cart: newCart,
        total: calcCartTotal(newCart),
      };
    });
  }

  set_stock(stock) {
    this.setState((prevState, props) => {
      let newStock = {};
      stock.forEach((item) => {
        let flavor = '';
        if ('Flavor' in item) {
          flavor = item.Flavor + ' ';
        }
        let key = item.Type + ' ' + item['Bottle Type'] + ' ' + flavor + item.Size;
        newStock[key] = item.Stock;

        // update prices
        this.products_prices[key] = item.Price;
      });

      return { stock: newStock };
    });
  }

  async componentDidMount() {
    // put all the synchronous calls before the promise/async calls
    const handler = (e) => this.setState({ mobile: e.matches });
    window.matchMedia('(max-width: 1200px)').addEventListener('change', handler);

    this.setState((prevState, props) => ({
      loading: false,
    }));

    fetch(process.env.REACT_APP_HOST + '/v1/stock', {
      method: `GET`,
    }).then((res) => {
      if (res.ok) {
        res.json().then((data) => {
          this.set_stock(data);
        });
      }
    });

    let loggedIn = await validateLoginSession();
    this.setState({ loggedIn: loggedIn });

    // update name (instead of `log in`) if user is logged in
    if (loggedIn) {
      let user = await fetchUserInfo();
      return { user: user };
    }
  }

  render() {
    var bottleTypes = [];
    var selectedType = this.state.selected.type;

    var imageType = this.state.selected.type;
    var imageBottle = this.state.selected.bottle;
    var imageFlavor = this.state.selected.flavor;
    var imageSize = this.state.selected.size;

    this.validConfigs[selectedType]['order'].forEach((bottleType) => {
      bottleTypes.push(
        <ProductButton
          text={bottleType}
          category={'bottle'}
          handleClick={this.handleClick}
          selected={this.state.selected.bottle}
        ></ProductButton>
      );
    });

    var sizes = [];
    var selectedBottle = this.state.selected.bottle;
    var selectedFlavor = '';

    if (this.state.selected.flavor != null) {
      selectedFlavor = this.state.selected.flavor + ' ';
    }

    this.validConfigs[selectedType][selectedBottle]['Sizes'].forEach((size) => {
      if (size === '6 x 500 mL' && imageFlavor === 'Lemongrass') {
        return;
      }
      var dbKey = selectedType + ' ' + selectedBottle + ' ' + selectedFlavor + size;
      sizes.push(
        <ProductButton
          text={size}
          category={'size'}
          stock={this.state.stock[dbKey]}
          handleClick={this.handleClick}
          selected={this.state.selected.size}
        ></ProductButton>
      );
    });

    var flavorButtons = [];
    if (selectedType === 'Flavored Sparkling') {
      this.validConfigs[selectedType][selectedBottle]['Flavors'].forEach((flavor) => {
        flavorButtons.push(
          <ProductButton
            text={flavor}
            category={'flavor'}
            handleClick={this.handleClick}
            selected={this.state.selected.flavor}
          ></ProductButton>
        );
      });
    }
    console.log('loggedIn: ' + this.state.loggedIn);
    return (
      <div className="App">
        <TimeoutModal></TimeoutModal>
        <Loading />

        <Bottle />

        <div id="section1">
          <NavBar
            loggedIn={this.state.loggedIn}
            name={this.state.user ? this.state.user.firstName : ''}
            total={this.state.total}
            cart={this.state.cart}
            checkout={false}
            plus={this.handlePlus}
            minus={this.handleMinus}
            delete={this.handleDelete}
          />
          <div className="justify-content-center align-items-start d-flex w-100" style={{ marginTop: -70 }}>
            <img id="big-logo" src="/icelandic_logo.png" alt="Icelandic Logo" />
          </div>
          <div id="main-container">
            <div id="container-child" className="col-xl-7 w-100">
              <CoffeeMornings text="Icelandic Water" classes="txt" id="main-txt"></CoffeeMornings>

              <GreatThings
                classes="txt"
                id="secondary-txt"
                text="EXCEPTIONALLY PURE: Icelandic Glacial is sourced from the legendary Ölfus Spring in Iceland, one of the
                world’s most pristine ecosystems."
              ></GreatThings>

              <div id="main-apply-container">
                <button type="button" id="main-apply" className="btn btnBlue ">
                  Order Now
                </button>
              </div>
            </div>

            <div className="col-xl-5"></div>
          </div>
        </div>
        <div id="bottom">
          <div className="pillarsContainer">
            <img className="pillar" src="/icelandic_pillars/Pillars/pic1.png" alt="Alkaline" />
            <img className="pillar" src={'/icelandic_pillars/Pillars/pic2.png'} alt="Exceptional Purity" />
            <img className="pillar" src={'/icelandic_pillars/Pillars/pic3.png'} alt="Carbon Neutral" />
            <img className="pillar" src={'/icelandic_pillars/Pillars/pic4.png'} alt="Award Winning" />
            <img className="pillar" src={'/icelandic_pillars/Pillars/pic5.png'} alt="Sustainable" />
          </div>

          <h1 id="ice-text">
            With a naturally occuring pH of 8.4, and an exceptionally low mineral content, our water is perfectly balanced.
          </h1>
          <div className="row product-section">
            <div id="product-container">
              <div id="config-container">
                <div className="product-configuration">
                  <div className="product-options justify-content-start">
                    <div id="type" className="product-config">
                      <span className="product-trait">Type&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>

                      <div className="water-choose">
                        <ProductButton
                          text={'Still'}
                          category="type"
                          handleClick={this.handleClick}
                          selected={this.state.selected.type}
                        ></ProductButton>
                        <ProductButton
                          text={'Sparkling'}
                          category="type"
                          handleClick={this.handleClick}
                          selected={this.state.selected.type}
                        >
                          {' '}
                        </ProductButton>
                        <ProductButton
                          text={'Flavored Sparkling'}
                          category="type"
                          big={true}
                          handleClick={this.handleClick}
                          selected={this.state.selected.type}
                        ></ProductButton>
                      </div>
                    </div>

                    <div id="bottle" className="product-config">
                      <span className="product-trait">Bottle&nbsp;&nbsp;&nbsp;</span>
                      <div className="bottle-choose">{bottleTypes}</div>
                    </div>
                    {flavorButtons.length > 0 && (
                      <div id="flavors" className="product-config">
                        <span className="product-trait">Flavors</span>
                        <div className="flavor-choose">{flavorButtons}</div>
                      </div>
                    )}
                    <div id="size" className="product-config">
                      <span className="product-trait">Size&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                      <div className="size-choose">{sizes}</div>
                    </div>
                    <div id="quantity" className="product-config">
                      <span className="product-trait">Quantity</span>
                      <button type="button" onClick={this.sub_quantity} className="btn quantity_objs">
                        -
                      </button>
                      <span id="curr-quantity" className="quantity_objs">
                        {this.state.selected.quantity}
                      </span>
                      <button type="button" onClick={this.add_quantity} className="btn quantity_objs  ">
                        +
                      </button>
                    </div>
                    {imageFlavor ? (
                      <>
                        {this.state.mobile && (
                          <img
                            className="product-image-mobile "
                            src={bottleImages[imageType][imageBottle][imageFlavor][imageSize]}
                            alt="horse"
                          />
                        )}
                      </>
                    ) : (
                      <>
                        {this.state.mobile && (
                          <img
                            className="product-image-mobile"
                            src={bottleImages[imageType][imageBottle][imageSize]}
                            alt="Bottle Image"
                          />
                        )}
                      </>
                    )}

                    <hr />

                    <div id="to-checkout" className="product-config d-flex flex-wrap">
                      <div id="total-row" className="row" style={{ flexDirection: 'column' }}>
                        <div id="total-container">
                          <span className="product-trait">SELECTION TOTAL</span>
                          <span className="product-trait" id="total">
                            {this.calc_selected_total(this.state.selected)} KD
                          </span>
                        </div>
                        <div id="cart-container">
                          <span className="product-trait" style={{ float: 'left' }}>
                            CART TOTAL
                          </span>
                          <span className="product-trait" id="total">
                            {this.state.total} KD
                          </span>
                        </div>
                      </div>
                      <div id="add-cart-container">
                        <button type="button" onClick={this.add_to_cart} id="add-cart" className="btn">
                          ADD TO CART
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {imageFlavor ? (
              <>
                {!this.state.mobile && (
                  <img
                    className="product-image "
                    src={bottleImages[imageType][imageBottle][imageFlavor][imageSize]}
                    alt="bottle image"
                  />
                )}
              </>
            ) : (
              <>
                {!this.state.mobile && (
                  <img
                    className="product-image "
                    src={bottleImages[imageType][imageBottle][imageSize]}
                    alt="bottle image"
                    style={imageSize === '12 x 1.5 L' || imageSize === '12 x 750 mL' ? { width: '300px' } : {}}
                  />
                )}
              </>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Main);
