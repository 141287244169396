import { calcCartTotal } from "./utils";

export function handlePlus(e) {
  this.setState((prevState, props) => {
    let sel = e;

    // console.log(props);
    // let sel = prevState.selected;

    // check if item has 0 stock and abort if so
    // still need to implement stock checking in these minor functions
    // let key = this.get_product_key(sel);
    // if (prevState.stock[key] === 0) {
    //   return null;
    // }

    // check if item is already in cart and update quantity
    let newCart = [];
    let updated = false;

    prevState.cart.forEach((item) => {
      if (sel.type === item.type && sel.bottle === item.bottle && sel.size === item.size && sel.flavor === item.flavor) {
        let copy = {};
        Object.assign(copy, item);
        copy.quantity += 1;
        updated = true;
        newCart.push(copy);
        return;
      }

      newCart.push(item);
    });

    localStorage.setItem('cart', JSON.stringify(newCart));
    return {
      cart: newCart,
      total: calcCartTotal(newCart),
    };
  });
}

export function handleMinus(e) {
  this.setState((prevState, props) => {
    let sel = e;

    // console.log(props);
    // let sel = prevState.selected;

    // check if item has 0 stock and abort if so
    // still need to implement stock checking in these minor functions
    // let key = this.get_product_key(sel);
    // if (prevState.stock[key] === 0) {
    //   return null;
    // }

    // check if item is already in cart and update quantity
    let newCart = [];
    let updated = false;

    prevState.cart.forEach((item) => {
      if (sel.type === item.type && sel.bottle === item.bottle && sel.size === item.size && sel.flavor === item.flavor) {
        let copy = {};
        Object.assign(copy, item);
        if (copy.quantity - 1 === 0) {
          return;
        }
        copy.quantity -= 1;
        updated = true;
        newCart.push(copy);
        return;
      }

      newCart.push(item);
    });

    localStorage.setItem('cart', JSON.stringify(newCart));
    return {
      cart: newCart,
      total: calcCartTotal(newCart),
    };
  });
}

export function handleDelete(e) {
  this.setState((prevState, props) => {
    let sel = e;

    // console.log(props);
    // let sel = prevState.selected;

    // check if item has 0 stock and abort if so
    // still need to implement stock checking in these minor functions
    // let key = this.get_product_key(sel);
    // if (prevState.stock[key] === 0) {
    //   return null;
    // }

    // check if item is already in cart and update quantity
    let newCart = [];
    let updated = false;

    prevState.cart.forEach((item) => {
      if (sel.type === item.type && sel.bottle === item.bottle && sel.size === item.size && sel.flavor === item.flavor) {
        return;
      }

      newCart.push(item);
    });

    localStorage.setItem('cart', JSON.stringify(newCart));
    return {
      cart: newCart,
      total: calcCartTotal(newCart),
    };
  });
}
