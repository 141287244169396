import * as React from 'react';
import * as QueryString from "query-string"
import NavBar from './NavBar';
import CartRow from './CartRow';
import { validateLoginSession, getUserInfo, fetchUserInfo, calcCartTotal } from './utils.js';
import { handleDelete, handleMinus, handlePlus } from './cartFunctions';
import { withRouter } from "react-router";
import ColoredCircularProgress from './ColoredCircularProgress';


class EmailVerification extends React.Component {
    constructor(props) {
        // Required step: always call the parent class' constructor
        super(props);
        let loggedIn = validateLoginSession();
        let cached_cart = localStorage.getItem('cart') ? JSON.parse(localStorage.getItem('cart')) : [];

        // Set the state directly. Use props if necessary.
        this.state = {
            total: calcCartTotal(cached_cart),
            cart: cached_cart,
            loading: true,
            error: false,
            loggedIn: loggedIn,
            user: loggedIn ? getUserInfo() : {},
        };

        this.handlePlus = handlePlus.bind(this);
        this.handleMinus = handleMinus.bind(this);
        this.handleDelete = handleDelete.bind(this);
    }

    async componentDidMount() {
        // update name (instead of `log in`) if user is logged in
        const handler = (e) => this.setState({ mobile: e.matches });
        window.matchMedia('(max-width: 950px)').addEventListener('change', handler);

        if (this.state.loggedIn) {
            let user = await fetchUserInfo();
            if (user) {
                this.setState({ user: user });
            }
        }

        let query = QueryString.parse(this.props.location.search);
        let token = query.token;

        // call verify-email endpoint
        let res = await fetch(process.env.REACT_APP_HOST + `/v1/auth/verify-email?token=${token}`, {
            method: `POST`,
            headers: {
                Accept: 'application/json',
            }
        });

        if (res.status !== 204) {
            this.setState({ error: true, loading: false });
        }
        else {
            this.setState({ loading: false });
        }
    }


    //   console.log(testvalue);
    render() {
        let rows = [];
        this.state.cart.forEach((item) => {
            rows.push(
                <CartRow
                    minus={this.handleMinus}
                    delete={this.handleDelete}
                    plus={this.handlePlus}
                    key={item.key}
                    item={item}
                ></CartRow>
            );
        });

        return (
            <div>
                <NavBar
                    loggedIn={this.state.loggedIn}
                    name={this.state.user ? this.state.user.firstName : ''}
                    total={this.state.total}
                    cart={this.state.cart}
                    checkout={false}
                    plus={this.handlePlus}
                    minus={this.handleMinus}
                    delete={this.handleDelete}
                />
                <div id="section1">
                    <div id="main-container">
                        {this.state.loading &&
                            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: 500 }}>
                                <ColoredCircularProgress />
                            </div>
                        }
                        <div id="container-child" className="col-xl-7 w-100">

                            {!this.state.error && !this.state.loading && (
                                <div>
                                    <div className="txt" id="confirmation-txt">
                                        Success!
                                    </div>
                                    <div className="darkTxt">
                                        Your email has been verified.
                                    </div>
                                </div>
                            )}
                            {this.state.error && !this.state.loading &&(
                                <div>
                                    <div className="uhOh">
                                        Uh oh!
                                    </div>
                                    <div className="errorTxt">
                                        There was an error processing your request, please try again or contact us for assistance.
                                    </div>
                                </div>
                            )}


                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(EmailVerification);
