
function getAuthHeader() {
    // return auth header with jwt if user is logged in and request is to the api url
    var tokens = localStorage.getItem('tokens');
    if (!tokens) {
        return {};
    }
    tokens = JSON.parse(tokens);
    if (tokens !== null && Object.keys(tokens).length  !== 0) {
        return { Authorization: `Bearer ${tokens.access.token}` };
    }
    return {};

}

function isAccessTokenExpired() {
    var tokens = localStorage.getItem('tokens')
    if (!tokens) {
        return false
    }
    tokens = JSON.parse(tokens)
    if (Date.parse(tokens.access.expires) < new Date()) {
        return true
    }
    return false
}


async function validateLoginSession() {
    // check if access token expired
    // if expired, refresh tokens
    // if refresh token expired or no tokens, user is logged out
    var tokens = localStorage.getItem('tokens')
    if (!tokens) {
        return false
    }

    tokens = JSON.parse(tokens)

    if (Date.parse(tokens.access.expires) < new Date()) {
        console.log("access expired")
        if (Date.parse(tokens.refresh.expires) < new Date()) {
            console.log("refresh expired")
            return false
        }
        await refreshTokens()
        return
    }
    onLoginSuccess(tokens);
    return true
}

async function logout() {

    let tokens = JSON.parse(localStorage.getItem('tokens'))

    var res = await fetch(process.env.REACT_APP_HOST + "/v1/auth/logout", {
        method: "POST",
        body: JSON.stringify({
            refreshToken: tokens.refresh.token
        }),
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
    })

    if (!res.ok) {
        throw new Error(`Failed to refresh tokens: ${res.statusText}`);
    }
    removeTokensAndRedirect()
}

async function sendResetPasswordEmail(email) {

    var res = await fetch(process.env.REACT_APP_HOST + "/v1/auth/forgot-password", {
        method: "POST",
        body: JSON.stringify({
            email: email
        }),
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
    })

    if (res.status === 404) {
        return
    }

    if (!res.ok) {
        alert(`Error sending password reset email: ${res.statusText}`);
    }
}

async function changePassword(password, token) {

    var res = await fetch(process.env.REACT_APP_HOST + "/v1/auth/reset-password?token=" + token, {
        method: "POST",
        body: JSON.stringify({
            password: password
        }),
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
    })
    if (res.status === 401) {
        throw new Error("Invalid token")
    }
    if (!res.ok) {
        throw new Error(`Error sending password reset email: ${res.statusText}`);
    }
}

function removeTokensAndRedirect() {
    localStorage.removeItem("user")
    localStorage.removeItem("tokens")
    window.location.href = "/"
}
function removeTokensAndRedirectToLogin() {
    localStorage.removeItem("user")
    localStorage.removeItem("tokens")
    window.location.href = "/login"
}

function onLoginSuccess(tokens) {
    setTimeout( refreshTokens, Date.parse(tokens.access.expires) - Date.now() )
    setTimeout( onRefreshTokenExpired, Date.parse(tokens.refresh.expires) - Date.now() )
}

function onRefreshTokenExpired() {
    localStorage.removeItem("user")
    localStorage.removeItem("tokens")
    window.location.href = "/timeout"
}

function getCart() {
    return localStorage.getItem('cart') ? JSON.parse(localStorage.getItem('cart')) : [];
}

function getCartTotal() {
    let cart = getCart()
    return calcCartTotal(cart)
}

function calcCartTotal(cart) {
    var tot = 0
    cart.forEach((item) => {
        tot += item.unitPrice * item.quantity;
    });
  
    return Math.round((tot + Number.EPSILON) * 100) / 100;
}

async function refreshTokens() {
    var tokens = JSON.parse(localStorage.getItem('tokens'))
    var res = await fetch(process.env.REACT_APP_HOST + "/v1/auth/refresh-tokens", {
        method: "POST",
        body: JSON.stringify({
            refreshToken: tokens.refresh.token
        }),
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
    })

    if (res.ok) {
        var newTokens = await res.json();
        onLoginSuccess(newTokens)
        localStorage.setItem('tokens', JSON.stringify(newTokens));
    }
    else {
        throw new Error(`Failed to refresh tokens: ${res.statusText}`);
    }
} 

function getUserInfo() {
    var user = localStorage.getItem('user')
    if (!user) {
        return null
    }
    return JSON.parse(user)
}

async function fetchUserInfo() {
    var currUser = localStorage.getItem('user');
    if (!currUser) {
        return null
    }
    currUser = JSON.parse(currUser)
    var res = await fetch(process.env.REACT_APP_HOST + '/v1/users/' + currUser.id, {
        headers: getAuthHeader(),
        method: "GET"
    });

    if (res.ok) {
        let user = await res.json();
        localStorage.setItem("user", JSON.stringify(user));
        return user
    } else if (res.status === 401) {
        if (isAccessTokenExpired()) {
            await refreshTokens()
            return fetchUserInfo()
        }
        // not logged in
        removeTokensAndRedirectToLogin()
    } else {
        console.log("error gettng user")
        throw new Error(`Failed to fetch user info: ${res.statusText}`);
    }
}

async function resendVerificationEmail() {
    var res = await fetch(process.env.REACT_APP_HOST + '/v1/auth/send-verification-email', {
        headers: getAuthHeader(),
        method: "POST"
    });

    if (res.status === 401) {
        if (isAccessTokenExpired()) {
            await refreshTokens()
            return fetchUserInfo()
        }
        // not logged in
        removeTokensAndRedirectToLogin()

    } else if (!res.ok) {
        alert(`Failed to resend verification email: ${res.statusText}`);
    }
}

async function getOrders() {
    var res = await fetch(process.env.REACT_APP_HOST + '/v1/orders/', {
        headers: getAuthHeader(),
        method: "GET"
    });
    if (res.ok) {
        return await res.json();
    } else if (res.status === 401) {
        if (isAccessTokenExpired()) {
            await refreshTokens()
            return await getOrders()
        }
        // not logged in
        removeTokensAndRedirectToLogin()
    } else {
        throw new Error(`Failed to fetch user info: ${res.statusText}`);
    }
}

async function updateUserProfile(user) {

    let id = user.id
    delete user.id

    let headers = getAuthHeader();
    headers['Content-Type'] = 'application/json';

    var res = await fetch(process.env.REACT_APP_HOST + '/v1/users/' + id, {
        headers: headers,
        method: "PATCH",
        body: JSON.stringify(user)
    });
    
    if (res.ok) {
        return await res.json();
    } else if (res.status === 401) {
        if (isAccessTokenExpired()) {
            await refreshTokens()
            user.id = id
            return updateUserProfile(user)
        }
        // not logged in
        removeTokensAndRedirectToLogin()
    } else {
        throw new Error(`Failed to update user profile: ${res.statusText}`);
    }
}

export {
    fetchUserInfo,
    getUserInfo,
    getAuthHeader,
    validateLoginSession,
    onLoginSuccess,
    getCartTotal,
    getCart,
    calcCartTotal,
    logout,
    getOrders,
    updateUserProfile,
    resendVerificationEmail,
    sendResetPasswordEmail,
    changePassword
}