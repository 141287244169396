import * as React from 'react';
import * as QueryString from 'query-string';
import NavBar from './NavBar';
import CartRow from './CartRow';
import { validateLoginSession, getUserInfo, fetchUserInfo, calcCartTotal } from './utils.js';
import { handleDelete, handleMinus, handlePlus } from './cartFunctions';
import { withRouter } from 'react-router';

class OrderConfirmation extends React.Component {
  constructor(props) {
    // Required step: always call the parent class' constructor
    super(props);
    let loggedIn = validateLoginSession();
    let cached_cart = localStorage.getItem('cart') ? JSON.parse(localStorage.getItem('cart')) : [];

    // Set the state directly. Use props if necessary.
    this.state = {
      total: calcCartTotal(cached_cart),
      cart: cached_cart,
      loggedIn: loggedIn,
      user: loggedIn ? getUserInfo() : {},
    };

    this.handlePlus = handlePlus.bind(this);
    this.handleMinus = handleMinus.bind(this);
    this.handleDelete = handleDelete.bind(this);
  }

  async componentDidMount() {
    // update name (instead of `log in`) if user is logged in
    const handler = (e) => this.setState({ mobile: e.matches });
    window.matchMedia('(max-width: 950px)').addEventListener('change', handler);

    let loggedIn = await validateLoginSession();

    this.setState({
        loggedIn: loggedIn
    })

    if (loggedIn) {
      let user = await fetchUserInfo();
      if (user) {
        this.setState({ user: user });
      }
    }
  }

  //   console.log(testvalue);
  render() {
    let rows = [];
    this.state.cart.forEach((item) => {
      rows.push(
        <CartRow
          minus={this.handleMinus}
          delete={this.handleDelete}
          plus={this.handlePlus}
          key={item.key}
          item={item}
        ></CartRow>
      );
    });

    let query = QueryString.parse(this.props.location.search);
    let orderNumber = query.ref;
    let error = query.error;

    if (!error) {
      localStorage.removeItem('cart');
    }

    return (
      <div>
        <div id="section1">
          <NavBar
            loggedIn={this.state.loggedIn}
            name={this.state.user ? this.state.user.firstName : ''}
            total={this.state.total}
            cart={this.state.cart}
            checkout={false}
            plus={this.handlePlus}
            minus={this.handleMinus}
            delete={this.handleDelete}
          />
          <div id="main-container">
            <div id="container-child" className="col-xl-7 w-100">
              {orderNumber && (
                <div>
                  <div className="txt" id="confirmation-txt">
                    Thank you for your order!
                  </div>
                  <div className="uhOh txt" style={{ paddingBottom: '0px', margin: '20px 0 10px 0', color: '#686868' }}>
                    Order #{orderNumber}
                  </div>
                  <div className="darkTxt">
                    Your order has been confirmed. You will receive a confirmation email shortly.
                  </div>
                </div>
              )}
              {error && (
                <div>
                  <div className="uhOh">Uh oh!</div>
                  {error === 'payment' && (
                    <div className="errorTxt">
                      There was an error processing your payment, please try again or contact us for assistance.
                    </div>
                  )}
                  {error === 'processing' && (
                    <div className="errorTxt">
                      There was an error processing your order, please contact us for assistance.
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(OrderConfirmation);
