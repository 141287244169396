import * as React from 'react';
import NavBar from './NavBar';
import CartRow from './CartRow';
import { validateLoginSession, getUserInfo, fetchUserInfo, calcCartTotal } from './utils.js';
import { handleDelete, handleMinus, handlePlus } from './cartFunctions';
import { withRouter } from "react-router";


class SignUpConfirmation extends React.Component {
  constructor(props) {
    // Required step: always call the parent class' constructor
    super(props);
    let loggedIn = validateLoginSession();
    let cached_cart = localStorage.getItem('cart') ? JSON.parse(localStorage.getItem('cart')) : [];

    // Set the state directly. Use props if necessary.
    this.state = {
      total: calcCartTotal(cached_cart),
      cart: cached_cart,
      loggedIn: loggedIn,
      user: loggedIn ? getUserInfo() : {},
    };

    this.handlePlus = handlePlus.bind(this);
    this.handleMinus = handleMinus.bind(this);
    this.handleDelete = handleDelete.bind(this);
  }

  async componentDidMount() {
    // update name (instead of `log in`) if user is logged in
    const handler = (e) => this.setState({ mobile: e.matches });
    window.matchMedia('(max-width: 950px)').addEventListener('change', handler);

    let loggedIn = await validateLoginSession();
    this.setState({
        loggedIn: loggedIn
    })
    if (loggedIn) {
      let user = await fetchUserInfo();
      if (user) {
        this.setState({ user: user });
      }
    }
  }

  render() {
    let rows = [];
    this.state.cart.forEach((item) => {
      rows.push(
        <CartRow
          minus={this.handleMinus}
          delete={this.handleDelete}
          plus={this.handlePlus}
          key={item.key}
          item={item}
        ></CartRow>
      );
    });

    return (
      <div>
        <NavBar
          loggedIn={this.state.loggedIn}
          name={this.state.user ? this.state.user.firstName : ''}
          total={this.state.total}
          cart={this.state.cart}
          checkout={false}
          plus={this.handlePlus}
          minus={this.handleMinus}
          delete={this.handleDelete}
        />
        <div id="section1">
          <div id="main-container">
            <div id="container-child" className="col-xl-7 w-100">
                <div>
                  <div className="txt" id="confirmation-txt">
                    Your account has been created!
                  </div>
                  <div className="darkTxt">
                    We've sent a verification email to <b>{this.state.user.email}</b>.
                    Please click the link in the email to verify your account.
                  </div>
                </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(SignUpConfirmation);
