import * as React from 'react';
import LoadingButton from '@mui/lab/LoadingButton';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { Link } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { withRouter } from 'react-router-dom';
import SignUpValidator from './SignUpValidator';
import './SignUp.css';
import { getAuthHeader } from './utils';

const theme = createTheme();

class SignUp extends React.Component {
  constructor(props) {
    // Required step: always call the parent class' constructor
    super(props);

    // Set the state directly. Use props if necessary.
    this.state = {
      loading: false,
      firstName: null,
      lastName: null,
      email: null,
      password: null,
      confirm: null,
      area: null,
      block: null,
      street: null,
      jadda: null,
      house: null,
      floor: null,
      phone: null,
      firstNameError: false,
      lastNameError: false,
      emailError: false,
      passwordError: false,
      confirmError: false,
      areaError: false,
      blockError: false,
      streetError: false,
      houseError: false,
      phoneError: false,
      emailTaken: false,
      emailHelperText: 'Please enter a valid email address',
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleFieldChange = this.handleFieldChange.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  async handleSubmit(event) {
    event.preventDefault();

    // run validation for all fields
    const fields = ['firstName', 'lastName', 'email', 'password', 'confirm', 'area', 'block', 'street', 'jadda', 'house', 'floor', 'phone'];
    const errors = {};

    let isValid = true
    for (let i = 0; i < fields.length; i++) {
      errors[fields[i] + "Error"] = !this.validateField(fields[i]);
      if (errors[fields[i] + "Error"]) {
        isValid = false
      }
    }

    this.setState(errors);

    if (!isValid) {
        return;
    }

    this.setState({ loading: true });

    var object = {};

    const data = new FormData(event.currentTarget);
    data.delete('confirm'); // remove the confirm password field
    data.forEach(function (value, key) {
      object[key] = value;
    });
    var json = JSON.stringify(object);

    let res = await fetch(process.env.REACT_APP_HOST + '/v1/auth/register', {
      method: `POST`,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: json,
    });
    if (res.ok) {
      let json = await res.json();
      localStorage.setItem('tokens', JSON.stringify(json.tokens));
      localStorage.setItem('user', JSON.stringify(json.user));
      await this.sendConfirmationEmail()
      window.location.href = '/signupConfirmation';
    } else if (res.status === 400) {
      let err = await res.json();
      if (err.message === 'Email is already in use') {
        this.setState({
          emailError: true,
          emailHelperText: err.message,
          loading: false,
        });
      } else {
        alert(err.message);
        this.setState({
          loading: false,
        })
      }
    } else {
      alert('Something went wrong: ' + res.statusText);
    }
  }

  validateField(field) {
    // validate field and return true if valid
    var isValid = true;
    if (field === 'confirm') {
        isValid = SignUpValidator.validate(field, this.state.confirm || '', this.state.password || '');
    } else if (field !== 'jadda' && field !== 'floor') {
        isValid = SignUpValidator.validate(field, this.state[field] || '');
    }
    return isValid;
  }

  handleFieldChange(e) {
    // update state and validate
    const { name, value } = e.target;
    const valid = this.validateField(name);

    this.setState({
        [name]: value,
        [name + "Error"]: !valid,
    });
  }

  async sendConfirmationEmail() {
    let res = await fetch(process.env.REACT_APP_HOST + '/v1/auth/send-verification-email', {
        method: `POST`,
        headers: getAuthHeader()
    });
    if (!res.ok) {
      alert('Something went wrong: ' + res.statusText);
    }
  }


  handleChange(e) {
    // update state. Validate only if there was an error previously
    this.setState((prevState) => {
      var isValid = true;
      var prevError = prevState[e.target.name + 'Error'];
      var prevEmailHelperText = prevState.emailHelperText;

      if (prevError) {
        if (e.target.name === 'confirm') {
          isValid = SignUpValidator.validate(e.target.name, e.target.value, prevState.password);
        } else if (e.target.name !== 'jadda' && e.target.name !== 'floor') {
          isValid = SignUpValidator.validate(e.target.name, e.target.value);
        }
      }

      // if email is changed, overwite the helper text
      // cuz helper text could've been changed to `email already taken`

      if (e.target.name === 'email') {
        prevEmailHelperText = 'Please enter a valid email address';
      }
      return {
        ...prevState,
        emailHelperText: prevEmailHelperText,
        [e.target.name]: e.target.value,
        [e.target.name + 'Error']: !isValid,
      };
    });
  }

  render() {
    return (
      <ThemeProvider theme={theme}>
        {/* <div className="grad"></div> */}
        <div className="signUpContainer my-4">
          <Container
            component="main"
            maxWidth="lg"
            className=" pl-0 pr-0 mr-0 ml-0 my-4"
            sx={{ width: 'min(90%, 600px)', backgroundColor: 'rgba(255,255,255,0.78)' }}
          >
            <div className="row">
              {/* <img className="signUpImage col-6" src="./signup-art.svg" /> */}
              <div className="px-5">
                <CssBaseline />
                <Box
                  sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    // maxWidth: "600px",
                    alignItems: 'center',
                  }}
                >
                  <Typography sx={{ fontSize: '40px', fontWeight: 'bold' }}>Sign up</Typography>
                  <Box component="form" noValidate onSubmit={this.handleSubmit} sx={{ mt: 3 }}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          autoComplete="given-name"
                          name="firstName"
                          required
                          fullWidth
                          id="firstName"
                          label="First Name"
                          onBlur={this.handleFieldChange}
                          onChange={this.handleChange}
                          error={this.state.firstNameError}
                          helperText={this.state.firstNameError ? 'Please enter your first name' : ''}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          required
                          fullWidth
                          id="lastName"
                          label="Last Name"
                          name="lastName"
                          onBlur={this.handleFieldChange}
                          onChange={this.handleChange}
                          error={this.state.lastNameError}
                          helperText={this.state.lastNameError ? 'Please enter your last name' : ''}
                          autoComplete="family-name"
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          required
                          fullWidth
                          id="email"
                          label="Email Address"
                          name="email"
                          onBlur={this.handleFieldChange}
                          onChange={this.handleChange}
                          error={this.state.emailError}
                          helperText={this.state.emailError ? this.state.emailHelperText : ''}
                          autoComplete="email"
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          required
                          fullWidth
                          name="phone"
                          label="Phone Number"
                          type="text"
                          id="phone"
                          onBlur={this.handleFieldChange}
                          onChange={this.handleChange}
                          helperText={this.state.phoneError ? 'Phone number must be between 7 and 14 digits long' : ''}
                          error={this.state.phoneError}
                          autoComplete="phone"
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          required
                          fullWidth
                          name="area"
                          label="Area"
                          type="text"
                          id="area"
                          error={this.state.areaError}
                          onBlur={this.handleFieldChange}
                          onChange={this.handleChange}
                          helperText={this.state.areaError ? 'Please enter your area' : ''}
                          autoComplete="area"
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          required
                          fullWidth
                          name="block"
                          label="Block"
                          type="text"
                          id="block"
                          error={this.state.blockError}
                          onBlur={this.handleFieldChange}
                          onChange={this.handleChange}
                          helperText={this.state.areaError ? 'Please enter your block' : ''}
                          autoComplete="block"
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          required
                          fullWidth
                          name="street"
                          label="Street"
                          type="text"
                          id="street"
                          error={this.state.streetError}
                          onBlur={this.handleFieldChange}
                          onChange={this.handleChange}
                          helperText={this.state.areaError ? 'Please enter your street' : ''}
                          autoComplete="street"
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          fullWidth
                          name="jadda"
                          label="Jadda (Optional)"
                          type="text"
                          id="jadda"
                          onBlur={this.handleFieldChange}
                          onChange={this.handleChange}
                          autoComplete="jadda"
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          required
                          fullWidth
                          name="house"
                          label="House"
                          type="text"
                          id="house"
                          error={this.state.houseError}
                          onBlur={this.handleFieldChange}
                          onChange={this.handleChange}
                          helperText={this.state.areaError ? 'Please enter your house' : ''}
                          autoComplete="house"
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          fullWidth
                          name="floor"
                          label="Floor (Optional)"
                          type="text"
                          id="floor"
                          onBlur={this.handleFieldChange}
                          onChange={this.handleChange}
                          autoComplete="floor"
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          required
                          fullWidth
                          name="password"
                          label="Password"
                          type="password"
                          id="password"
                          error={this.state.passwordError}
                          helperText={
                            this.state.passwordError
                              ? 'Password must be at least 8 characters long and contain at least one letter and number'
                              : ''
                          }
                          autoComplete="new-password"
                          onBlur={this.handleFieldChange}
                          onChange={this.handleChange}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          required
                          fullWidth
                          name="confirm"
                          label="Confirm Password"
                          type="password"
                          id="confirm"
                          error={this.state.confirmError}
                          helperText={this.state.confirmError ? 'Passwords do not match' : ''}
                          autoComplete="new-password"
                          onBlur={this.handleFieldChange}
                          onChange={this.handleChange}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <FormControlLabel
                          control={<Checkbox value="allowExtraEmails" color="primary" />}
                          label="I want to receive inspiration, marketing promotions and updates via email."
                        />
                      </Grid>
                    </Grid>
                    <LoadingButton
                      type="submit"
                      fullWidth
                      loading={this.state.loading}
                      variant="contained"
                      sx={{ mt: 3, mb: 2, backgroundColor: '#213B61' }}
                    >
                      Sign Up
                    </LoadingButton>
                    <Grid container justifyContent="flex-start">
                      <Grid item>
                        <Link to="/" variant="body2" style={{ color: '#213B61', textDecoration: 'underline' }}>
                          Already have an account? Sign in
                        </Link>
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
                <Typography variant="body2" color="text.secondary" align="center" sx={{ mt: 4, mb: 2 }}>
                  {'Copyright © '}
                  <Link to="/" style={{ color: 'inherit', textDecoration: 'underline' }}>
                    Icelandic
                  </Link>{' '}
                  {new Date().getFullYear()}
                  {'.'}
                </Typography>
              </div>
            </div>
          </Container>
        </div>
      </ThemeProvider>
    );
  }
}

export default withRouter(SignUp);
