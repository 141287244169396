import * as React from 'react';
import Badge from '@mui/material/Badge';
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';

const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    right: -3,
    top: 13,
    color: 'rgb(33, 59, 97)',
    backgroundColor: 'rgb(179,220,250)',
    padding: '0 4px',
  },
}));
const shapeStyles = { color: 'rgb(33, 59, 97)',};

class NavCart extends React.Component {
  render() {
    return (
      // <div className="row d-flex justify-content-center align-items-center pl-4">
      //   <button type="button" id="cart" className="btn" data-toggle="modal" data-target="#exampleModal">
      //     <img src="image_5.svg" width="32" height="32" alt="Cart icon" />
      //   </button>
      //   <div id="cart-amount"> {this.props.total} KD </div>
      // </div>
      <IconButton aria-label="cart" sx={{marginLeft: "20px", borderRadius: "4px"}} id="cart" className="btn" data-toggle="modal" data-target="#exampleModal">
      <StyledBadge badgeContent={this.props.count}>
      <span style={{ fontSize: "40px" }}>

        <ShoppingCartIcon fontSize="inherit" sx={{...shapeStyles}}/>
        </span>
      </StyledBadge>
    </IconButton>
    );
  }
}

export default NavCart;
