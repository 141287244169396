import React from 'react';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import DeleteIcon from '@mui/icons-material/Delete';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';

class CartRow extends React.Component {
  round_total(unitPrice, quantity) {
    let tot = quantity * unitPrice;
    return Math.round((tot + Number.EPSILON) * 100) / 100;
  }

  render() {
    let editable = this.props.editable;
    if (editable === undefined) {
      editable = true;
    }


    return (
      <li className="list-group-item cartrow" style={this.props.style}>
        <div className="main-line-descrepsh">
          <div className="row item-row pb-2">
            <div className="col-2 d-flex flex-row p-0 justify-content-center">
                {editable && (
                    <ButtonGroup className="quantity-change" orientation="vertical" aria-label="vertical outlined button group">
                    <AddCircleIcon fontSize="small" onClick={() => this.props.plus(this.props.item)} className="plus" style={{cursor: "pointer", color: "rgb(201 201 201)"}} />
                    <RemoveCircleIcon fontSize="small" onClick={() => this.props.minus(this.props.item)} className="minus" key="minus" style={{cursor: "pointer", color: "rgb(201 201 201)"}} />
                    </ButtonGroup>
                )}
              <div className="quantity-square ml-3">
                {this.props.item.quantity}
              </div>

            </div>
            <div className="descrepsh col-6">
              {this.props.item.type}
              <div className="subdescrepsh">
                <div>{this.props.item.bottle}</div>
                <div>{this.props.item.flavor}</div>
                <div>{this.props.item.size}</div>
              </div>
            </div>

            <div className="price-list col-4">
              {this.round_total(this.props.item.unitPrice, this.props.item.quantity)} KD
              {editable && (
              <div>
                <DeleteIcon className="trash ml-sm-3 mt-3" onClick={() => this.props.delete(this.props.item)} style={{cursor: "pointer", color: "rgb(201 201 201)"}} />
              </div>
              )}
            </div>
          </div>
        </div>
      </li>
    );
  }
}

export default CartRow;
