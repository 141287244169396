import * as React from 'react';
import NavBar from './NavBar';
import CartRow from './CartRow';
import { validateLoginSession, getUserInfo, fetchUserInfo, calcCartTotal, changePassword } from './utils.js';
import { handleDelete, handleMinus, handlePlus } from './cartFunctions';
import { withRouter } from 'react-router';
import { Box, Grid, TextField } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import SignUpValidator from './SignUpValidator';
import * as QueryString from 'query-string';

class ResetPassword extends React.Component {
  constructor(props) {
    // Required step: always call the parent class' constructor
    super(props);
    let loggedIn = false;
    let cached_cart = localStorage.getItem('cart') ? JSON.parse(localStorage.getItem('cart')) : [];

    let query = QueryString.parse(this.props.location.search);
    let token = query.token;

    // Set the state directly. Use props if necessary.
    this.state = {
      total: calcCartTotal(cached_cart),
      cart: cached_cart,
      submitted: false,
      password: '',
      confirm: '',
      token: token,
      passwordError: false,
      confirmError: false,
      passwordError: false,
      loading: false,
      error: false,
      loggedIn: loggedIn,
      user: loggedIn ? getUserInfo() : {},
    };

    this.handlePlus = handlePlus.bind(this);
    this.handleMinus = handleMinus.bind(this);
    this.handleDelete = handleDelete.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleFieldChange = this.handleFieldChange.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  async componentDidMount() {
    // update name (instead of `log in`) if user is logged in
    const handler = (e) => this.setState({ mobile: e.matches });
    window.matchMedia('(max-width: 950px)').addEventListener('change', handler);

    // let loggedIn = await validateLoginSession();
    // this.setState({ loggedIn: loggedIn });

    // if (!loggedIn) {
    //   this.props.history.push('/login');
    //   return;
    // }
    // let user = await fetchUserInfo();
    // if (user) {
    //   this.setState({ user: user });
    // } else {
    //   throw new Error('User not found');
    // }
  }

  async handleSubmit(e) {
    e.preventDefault();

    // run validation for all fields
    const fields = ['password', 'confirm'];
    const errors = {};

    let isValid = true;
    for (let i = 0; i < fields.length; i++) {
      errors[fields[i] + 'Error'] = !this.validateField(fields[i], this.state[fields[i]]);
      if (errors[fields[i] + 'Error']) {
        isValid = false;
      }
    }

    this.setState(errors);

    if (!isValid) {
      return;
    }

    this.setState({ loading: true });
    try {
      await changePassword(this.state.password, this.state.token);
      this.setState({ loading: false, submitted: true });
    } catch (e) {
      console.log(e);
      if (e.message === 'Invalid token') {
        // show error message
        this.setState({ token: null, loading: false });
      } else {
        this.setState({ loading: false });
        alert(e.message);
      }
    }
  }

  handleFieldChange(e) {
    const { name, value } = e.target;
    const valid = this.validateField(name, value);

    this.setState({
      [name]: value,
      [name + 'Error']: !valid,
    });
  }

  validateField(field, value) {
    // validate field and return true if valid
    var isValid = true;
    if (field === 'confirm') {
      isValid = SignUpValidator.validate(field, value || '', this.state.password || '');
    } else {
      isValid = SignUpValidator.validate(field, value || '');
    }
    return isValid;
  }

  handleChange(e) {
    this.setState((prevState) => {
      var isValid = true;
      var prevError = prevState[e.target.name + 'Error'];

      if (prevError) {
        isValid = this.validateField(e.target.name, e.target.value);
      }

      return {
        ...prevState,
        [e.target.name]: e.target.value,
        [e.target.name + 'Error']: !isValid,
      };
    });
  }

  render() {
    let rows = [];
    this.state.cart.forEach((item) => {
      rows.push(
        <CartRow
          minus={this.handleMinus}
          delete={this.handleDelete}
          plus={this.handlePlus}
          key={item.key}
          item={item}
        ></CartRow>
      );
    });

    return (
      <div>
        <NavBar
          loggedIn={this.state.loggedIn}
          name={this.state.user ? this.state.user.firstName : ''}
          total={this.state.total}
          cart={this.state.cart}
          checkout={false}
          plus={this.handlePlus}
          minus={this.handleMinus}
          delete={this.handleDelete}
        />
        <div id="section1" style={{ height: 'inherit' }}>
          <div style={{ marginTop: 200 }}>
            {this.state.token ? (
              <React.Fragment>
                {!this.state.submitted ? (
                  <Box
                    component="form"
                    noValidate
                    onSubmit={this.handleSubmit}
                    sx={{
                      marginTop: 8,
                      display: 'flex',
                      margin: '0 20px 0 20px',
                      flexDirection: 'column',
                      alignItems: 'center',
                    }}
                  >
                    <h4>Reset Password</h4>
                    <Grid container spacing={2} sx={{ maxWidth: 'min(90%, 400px)', margin: '0 !important' }}>
                      <Grid item xs={12} sx={{ paddingTop: 0, paddingLeft: '0 !important' }}>
                        <TextField
                          fullWidth
                          id="password"
                          label="Password"
                          name="password"
                          type="password"
                          error={this.state.passwordError}
                          sx={{
                            marginTop: '20px',
                            backgroundColor: 'white',
                            marginBottom: '20px',
                          }}
                          helperText={
                            this.state.passwordError
                              ? 'Password must be at least 8 characters long and contain at least one letter and number'
                              : ''
                          }
                          autoComplete="password"
                          onBlur={this.handleFieldChange}
                          onChange={this.handleChange}
                        />
                      </Grid>
                      <Grid item xs={12} sx={{ paddingTop: '0 !important', paddingLeft: '0 !important' }}>
                        <TextField
                          fullWidth
                          id="confirm"
                          label="Confirm Password"
                          name="confirm"
                          type="password"
                          helperText={this.state.confirmError ? 'Passwords do not match' : ''}
                          error={this.state.confirmError}
                          onBlur={this.handleFieldChange}
                          onChange={this.handleChange}
                          autoComplete="confirm"
                        />
                      </Grid>
                      <LoadingButton
                        loading={this.state.loading}
                        type="submit"
                        id="submitReset"
                        size="large"
                        fullWidth
                        variant="contained"
                        sx={{
                          mt: 5,
                          mb: 2,
                          backgroundColor: '#213B61',
                          textTransform: 'none',
                          '& .MuiLoadingButton-loadingIndicator': {
                            color: 'white',
                          },
                          '& .MuiCircularProgress-root': {
                            width: '25px !important',
                            height: '25px !important',
                          },
                        }}
                        onClick={this.handleSubmit}
                      >
                        {this.state.loading ? '' : 'Submit'}
                      </LoadingButton>
                    </Grid>
                  </Box>
                ) : (
                  <div className="d-flex align-items-center p-4 flex-column" style={{ color: '#4A6997' }}>
                    <div style={{ textAlign: 'left' }}>
                      <h3>Success!</h3>
                      <h5 style={{ color: 'gray' }}>Your password has been changed.</h5>
                    </div>
                  </div>
                )}
              </React.Fragment>
            ) : (
              <div style={{ color: '#4A6997', marginLeft: 50 }}>
                <h1>Uh oh!</h1>
                <h3 style={{ color: 'gray' }}>There was an error processing your request.</h3>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(ResetPassword);
