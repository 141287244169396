import React from 'react';
import CartRow from './CartRow';
import { withRouter } from 'react-router-dom';
import LinkButton from './LinkButton';



const linkStyle = {
  width: '100%',
};

class Cart extends React.Component {
  hide_modal() {
    // use window.$ because jquery is imported in <script> tag in index.html
    window.$('#exampleModal').modal('hide');
  }
  render() {
    let rows = [];
    this.props.cart.forEach((item) => {
      rows.push(
        <CartRow
          plus={this.props.plus}
          minus={this.props.minus}
          delete={this.props.delete}
          key={item.key}
          item={item}
        ></CartRow>
      );
    });

    return (
      <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h3 className="modal-title" id="exampleModalLabel">
                Cart
              </h3>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              {rows.length === 0 && <div id="empty-text">Your cart is empty.</div>}
              <ul className="list-group  cart-items">{rows}</ul>
            </div>
            <div className="modal-footer">
              {(rows.length > 0 && this.props.loggedIn) && (
                <LinkButton
                  to='/checkout'
                  type="button" id="checkoutBtn" className="btn btnBlue" data-dismiss="modal"
                  style={linkStyle}
                >
                    Go to checkout - {this.props.total} KD<span className="cart_total"></span>{' '}
                </LinkButton>
              )}
              {(rows.length > 0 && !this.props.loggedIn) && (
                <button
                type="button" id="checkoutBtn" className="btn btnBlue" data-dismiss="modal"
                style={linkStyle} onClick={() => this.props.toggleLoginModal()}
              >
                  Go to checkout - {this.props.total} KD<span className="cart_total"></span>{' '}
              </button>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Cart);
