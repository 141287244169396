import * as React from 'react';
import { threjasCode } from './animations.js';
import InnerHTML from 'dangerously-set-html-content';

class Bottle extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div id="threjas">
        <InnerHTML html={threjasCode} />
      </div>
    );
  }
}

export default Bottle;
