import React from 'react';
import { withRouter } from 'react-router-dom';
import './Main.css';
import { CircularProgress, ThemeProvider, createTheme } from '@mui/material';


class ColoredCircularProgress extends React.Component {

    render() {

        console.log(theme)

        theme.palette.primary.main = this.props.color || "#435C82"

        return (
            <React.Fragment>
                <ThemeProvider theme={theme}>
                    <CircularProgress />
                </ThemeProvider>
            </React.Fragment>
        );
    }
}

const theme = createTheme({
    palette: {
        primary: {
            main: "#435C82",
        }
    }
  });
export default withRouter(ColoredCircularProgress);
