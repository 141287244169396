import React, { Component } from "react";
import anime from "animejs";

export class CoffeeMornings extends Component {

  componentDidMount() {

        var animation = anime.timeline({ loop: false });

        animation.add({
            targets: '.ml9 .letter',
            scale: [0, 1],
            duration: 1500,
            elasticity: 600,
            delay: (el, i) => 45 * (i+1)
        })

        
  }

  render() {
    return (
    <h1 className={this.props.classes + " ml9"} id={this.props.id}>
        <span className="text-wrapper">
        {this.props.text.split("").map((v, index) => (
            // if v is a space, nbsp
            v === " " ? <span key={index} className="letter">&nbsp;</span> : <span key={index} className="letter">{v}</span>
        ))}
        </span>
      </h1>
    );
  }
}