import React from 'react';
import { Link } from 'react-router-dom';
import { onLoginSuccess, sendResetPasswordEmail } from './utils';
import LoadingButton from '@mui/lab/LoadingButton';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { withRouter } from 'react-router-dom';
import Modal from '@mui/material/Modal';
import LinkButton from './LinkButton';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 'min(90vw, 500px)',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};
class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      invalid: false,
      emailError: false,
      passwordError: false,
      errorMsg: null,
      loading: false,
      resetLoading: false,
      forgot: 0,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.resetPassword = this.resetPassword.bind(this);
    this.forgotPass = this.forgotPass.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.backToLogin = this.backToLogin.bind(this);
  }

  forgotPass() {
    this.setState({
      forgot: 1,
      emailError: false,
      passwordError: false,
    });
  }

  backToLogin() {
    this.setState({
      forgot: 0,
      invalid: false,
      emailError: false,
      passwordError: false,
    });
  }

  async resetPassword(e) {
    e.preventDefault();
    if (this.state.email === '') {
      this.setState({
        emailError: true,
      });
      return;
    } else if (this.state.emailError) {
      this.setState({
        emailError: false,
      });
    }

    this.setState({ resetLoading: true });
    await sendResetPasswordEmail(this.state.email);
    this.setState({ resetLoading: false });
    this.props.history.push('/forgotConfirmation');
  }

  handleCloseModal() {
    this.setState({
      forgot: 0,
      invalid: false,
    });
    this.props.toggleModal();
  }

  async handleSubmit(e) {
    e.preventDefault();
    let isValid = true;

    if (this.state.email === '') {
      this.setState({ emailError: true });
      isValid = false;
    } else if (this.state.emailError) {
      this.setState({ emailError: false });
      isValid = false;
    }
    if (this.state.password === '') {
      this.setState({ passwordError: true });
    } else if (this.state.passwordError) {
      this.setState({ passwordError: false });
    }

    if (!isValid) {
      return;
    }

    this.setState({ loading: true });

    var json = JSON.stringify({
      email: this.state.email,
      password: this.state.password,
    });

    let res = await fetch(process.env.REACT_APP_HOST + '/v1/auth/login', {
      method: `POST`,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: json,
    });
    if (res.ok) {
      let json = await res.json();
      localStorage.setItem('tokens', JSON.stringify(json.tokens));
      localStorage.setItem('user', JSON.stringify(json.user));
      onLoginSuccess(json.tokens);

      // refresh
      this.props.history.push('/');
      this.props.history.go(0);
    } else if (res.status === 401) {
      this.setState({ loading: false, invalid: true });
    } else {
      alert('Something went wrong: ' + res.statusText);
      this.setState({ loading: false });
    }
  }

  render() {
    return (
      <Modal
        open={this.props.open}
        onClose={this.handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <h3 className="modal-title" iid="modal-modal-title">
            {this.state.forgot === 0 ? 'Sign in' : 'Reset Password'}
          </h3>
          <div className="modal-body" id="modal-modal-description">
            <Box component="form" noValidate onSubmit={this.handleSubmit}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  id="email"
                  label="Email"
                  name="email"
                  error={this.state.emailError}
                  onChange={(e) => {
                    this.setState({ email: e.target.value });
                  }}
                  autoComplete="email"
                  sx={{
                    backgroundColor: 'white',
                  }}
                />
              </Grid>
              {this.state.forgot === 0 && (
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    id="password"
                    label="Password"
                    name="password"
                    type="password"
                    error={this.state.passwordError}
                    onChange={(e) => {
                      this.setState({ password: e.target.value });
                    }}
                    sx={{
                      marginTop: '20px',
                      backgroundColor: 'white',
                      marginBottom: '20px',
                    }}
                    autoComplete="password"
                  />
                </Grid>
              )}
              <Container disableGutters>
                {this.state.forgot === 0 ? (
                  <div>
                    {this.state.invalid && <div className="invalidText">Invalid email or password.</div>}
                    <LoadingButton
                      type="submit"
                      id="login"
                      size="large"
                      fullWidth
                      loading={this.state.loading}
                      variant="contained"
                      sx={{
                        mt: 3,
                        mb: 2,
                        backgroundColor: '#213B61',
                        textTransform: 'none',
                        '& .MuiLoadingButton-loadingIndicator': {
                          color: 'white',
                        },
                        '& .MuiCircularProgress-root': {
                          width: '25px !important',
                          height: '25px !important',
                        },
                      }}
                    >
                      {this.state.loading ? '' : 'Login'}
                    </LoadingButton>

                    {
                      <LinkButton to="/checkout" type="button" id="guest" className="btn">
                        Checkout as Guest
                      </LinkButton>
                    }
                  </div>
                ) : (
                  <div>
                    <LoadingButton
                      size="large"
                      onClick={this.resetPassword}
                      fullWidth
                      id="reset"
                      loading={this.state.resetLoading}
                      variant="contained"
                      sx={{
                        backgroundColor: '#213B61',
                        textTransform: 'none',
                        '& .MuiLoadingButton-loadingIndicator': {
                          color: 'white',
                        },
                        '& .MuiCircularProgress-root': {
                          width: '25px !important',
                          height: '25px !important',
                        },
                      }}
                    >
                      {this.state.resetLoading ? '' : 'Submit'}
                    </LoadingButton>
                    <LinkButton
                      onClick={this.backToLogin}
                      type="button"
                      id="guest"
                      className="btn"
                      sx={{ backgroundColor: '#213B61', textTransform: 'none' }}
                    >
                      Back to Login
                    </LinkButton>
                  </div>
                )}
              </Container>
            </Box>
          </div>
          <div className="modal-footer" style={{ paddingBottom: '0', display: 'block' }}>
            <h4 className="signup-txt" style={{ textAlign: 'left', width: '100%' }}>
              Don't have an account?&nbsp;
              <Link to="/signup" sx={{ width: '100%' }}>
                Sign up.
              </Link>
            </h4>
            {this.state.forgot === 0 && (
              <LinkButton
                type="button"
                className="btn"
                onClick={this.forgotPass}
                style={{ padding: 0, margin: 0, color: 'rgb(92,92,92)' }}
              >
                Forgot password
              </LinkButton>
            )}
          </div>
        </Box>
      </Modal>
    );
  }
}

export default withRouter(Login);
