import React from 'react';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import DeleteIcon from '@mui/icons-material/Delete';
import './OrderSubRow.css';

class OrderSubRow extends React.Component {
  round_total(unitPrice, quantity) {
    let tot = quantity * unitPrice;
    return Math.round((tot + Number.EPSILON) * 100) / 100;
  }

  render() {
    let editable = this.props.editable;
    if (editable === undefined) {
      editable = true;
    }
    return (
      <li className="list-group-item white-back" style={this.props.containerStyle}>
        <div className="main-line-descrepsh">
          <div className="row item-row">
            <div className=" col-1 m-0 mr-3 pl-0 p-sm-2 m-sm-0">
              <div className="quantity-square" style={this.props.quantityStyle}>
                {this.props.item.quantity}
              </div>
            </div>
            <div className="descrepsh col-7" style={this.props.descrepshStyle}>
              {this.props.item.type}
              <div className="subdescrepsh" style={this.props.subdescrepshStyle}>
                <div>{this.props.item.bottle}</div>
                <div>{this.props.item.flavor}</div>
                <div>{this.props.item.size}</div>
              </div>
            </div>

            <div className="price-list col-3 col-sm-4 " style={this.props.totalStyle}>
              {this.round_total(this.props.item.unitPrice, this.props.item.quantity)} KD
            </div>
          </div>
        </div>
      </li>
    );
  }
}

export default OrderSubRow;
