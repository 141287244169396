import React from 'react';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import {withRouter} from 'react-router-dom';


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
};
  

class TimeoutModal extends React.Component {

    render() {

        return (
        <Modal
            open={this.props.location.pathname === "/timeout"}
            onClose={() => this.props.history.push("/")}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <Typography id="modal-modal-title" variant="h6" component="h2">
                Your session has timed out
              </Typography>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                Please login again to continue shopping.
              </Typography>
            </Box>
        </Modal>
        )
    }
}

export default withRouter(TimeoutModal);