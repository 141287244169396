export const threjasCode = `
<script type="module">
import * as THREE from 'https://threejsfundamentals.org/threejs/resources/threejs/r122/build/three.module.js'; 
import {GLTFLoader} from 'https://threejsfundamentals.org/threejs/resources/threejs/r122/examples/jsm/loaders/GLTFLoader.js';
import {DRACOLoader} from 'https://threejsfundamentals.org/threejs/resources/threejs/r122/examples/jsm/loaders/DRACOLoader.js';

const scene = new THREE.Scene();

const loader = new GLTFLoader();
const draco = new DRACOLoader();
draco.setDecoderConfig({ type: 'js' });
draco.setDecoderPath('https://www.gstatic.com/draco/versioned/decoders/1.4.1/');

loader.setDRACOLoader(draco);

var small = true
if ($(window).width() >= 1050) {
    small = false
}
// loader.preload();

var bottle;
loader.load(
// resource URL
    'bottle/scene.gltf',
    // called when the resource is loaded
    async function ( gltf ) {
        setTimeout(function() {}, 900);
        $(".loading").addClass("hidden");
        gltf.scene.position.set(0,0,0)
        
        bottle = gltf.scene.children[0].children[0].children[0]
        bottle.children[1].remove()

        bottle.scale.set(0.02,0.02,0.02)
        bottle.updateMatrix();

        const MainObject = new THREE.Object3D();
        MainObject.add(bottle)

        bottle.position.set(0,0,0)
        // bottle.rotation.set(-Math.PI/2,0,Math.PI/2)
        bottle = MainObject

        const light = new THREE.AmbientLight( 0x404040 ); // soft white light
        
        scene.add( light );
        scene.add(bottle);

        bottle.position.y = -8
        if (!small) {
            bottle.position.x = 3
        } else {
            bottle.position.x = 0.25
            bottle.scale.set(0.45,0.45,0.45)
        }

        addAnimations(bottle)

    },
    // called when loading has errors
    function ( error ) {
        console.log( error );
    }
);

// import {OrbitControls} from 'https://threejsfundamentals.org/threejs/resources/threejs/r122/examples/jsm/controls/OrbitControls.js'; 
gsap.registerPlugin(ScrollTrigger);

const camera = new THREE.PerspectiveCamera(30, window.innerWidth / window.innerHeight, 0.1, 500);

const renderer = new THREE.WebGLRenderer({
    antialias: true,
    logarithmicDepthBuffer: true,
    alpha: true
});


const viewHeight = window.innerHeight
const viewWidth = window.innerWidth

// const controls = new OrbitControls( camera, renderer.domElement );

renderer.outputEncoding = THREE.sRGBEncoding;
renderer.setSize(window.innerWidth, window.innerHeight);
document.getElementById("threjas").appendChild(renderer.domElement);

window.addEventListener('resize', () => {
    renderer.setSize(window.innerWidth, window.innerHeight);
    camera.aspect = window.innerWidth / window.innerHeight
    camera.updateProjectionMatrix()
})

const geometry = new THREE.BoxGeometry();
const material = new THREE.MeshLambertMaterial({
    color: 0x00ff00
});

var light = new THREE.DirectionalLight('white', 2);
light.position.set(10, 10, 10);
scene.add(light)

light = new THREE.DirectionalLight('white', 2);
light.position.set(-10, 10, 10);
scene.add(light)

camera.position.z = 10;


function addAnimations(bottle) {

    let startY = -2
    let endY = -1.2
    let endX = 7
    if (small) {
        startY = -1.7
        endY = -0.7
        endX = 3
    }


    const tl = new TimelineMax().delay(.3)
    const posTl = gsap.timeline({
        scrollTrigger: {
                trigger: "#threjas",
                start: "top top", // when the top of the trigger hits the top of the viewport
                end: () => \`+=${window.innerHeight}\`, // end after scrolling 500px beyond the start
                scrub: 2, // smooth scrubbing, takes 1 second to "catch up" to the scrollbar
                // markers: true
            },
    })

    posTl.to(bottle.position, 1, {
        x: 0,
        ease: "power2.inOut",
    })

    posTl.to(bottle.position, 1, {
        x: endX,
        y: endY,
        ease: "power2.inOut",
    })

    tl.to(bottle.position, 2, {
        y: startY,
        ease: Expo.easeOut
    })

    tl.to(bottle.rotation, 2, {
        x: 0,
        ease: Expo.easeOut
    }, "=-2")


    gsap.to(bottle.rotation, {
            scrollTrigger: {
                trigger: "#threjas",
                start: "top top", // when the top of the trigger hits the top of the viewport
                end: () => \`+=${window.innerHeight}\`, // end after scrolling 500px beyond the start
                scrub: 2, // smooth scrubbing, takes 1 second to "catch up" to the scrollbar
            },
            z: Math.PI/180 * 20,
            ease: "power2.inOut",
            yoyo: true,
            repeat: 1
        })

    gsap.to(bottle.rotation, {
        scrollTrigger: {
            trigger: "#threjas",
            start: "top top", // when the top of the trigger hits the top of the viewport
            end: () => \`+=${window.innerHeight}\`, // end after scrolling 500px beyond the start
            scrub: 2, // smooth scrubbing, takes 1 second to "catch up" to the scrollbar
        },
        immediateRender: false,
        y: 2 * Math.PI,
        // repeat: -1,
        ease: "power2.inOut",
    })

    gsap.to(bottle.scale, {
                scrollTrigger: {
                    trigger: "#threjas",
                    start: "top top", // when the top of the trigger hits the top of the viewport
                    end: "+=" + 500, // end after scrolling 500px beyond the start
                    scrub: 1, // smooth scrubbing, takes 1 second to "catch up" to the scrollbar
                    // markers: true
                },
                x: 0.5,
                y: 0.5,
                z: 0.5,
                ease: "power2.inOut",
                // yoyo: true,
                // repeat: 1
    })
}

function animate() {

    requestAnimationFrame(animate);
    renderer.render(scene, camera);
}
animate();


</script>
`;
