
class SignUpValidator {

    static validate(name, ...values) {
        if (name === "email") {
            return SignUpValidator.email(...values)
        }
        if (name === "password") {
            return SignUpValidator.password(...values)
        }
        if (name === "phone") {
            return SignUpValidator.phone(...values)
        }
        if (name === "confirm") {
            return SignUpValidator.confirm(...values)
        }
        
        return SignUpValidator.notEmpty(...values)
    }

    static email(email) {
        return email !== "" && /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
    }

    static password(password) {
        return password !== "" && password.length > 8 && password.match(/\d/) && password.match(/[a-zA-Z]/)
    }

    static phone(phone) {
        return phone !== "" && phone.match(/^[+]{0,1}[0-9]{7,14}/) && !phone.match(/[a-zA-Z]/)
    }

    // fill out the rest
    static confirm(confirm, password) {
        return confirm !== "" && confirm === password
    }

    static notEmpty(value) {
        return value !== ""
    }


}

export default SignUpValidator
