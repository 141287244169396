import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import './OrderTable.css';
import OrderSubRow from "./OrderSubRow"
import json2mq from 'json2mq';
import useMediaQuery from '@mui/material/useMediaQuery';



function createData(currentOrder) {
  let orderNumber = currentOrder["id"];
  let date = timeConverter(currentOrder["createdAt"]);
  let total = currentOrder["total"];
  let status = currentOrder["status"];
  let paymentType = currentOrder["paymentType"];
  
  let breakdown = [];

  if(currentOrder["products"]){
    currentOrder["products"].forEach(x => {
      let currentProduct = {type: x["Type"], bottle: x["Bottle Type"], flavor: x["Flavor"], size: x["Size"], unitPrice: x["Price"], quantity: x["Quantity"]};
      breakdown.push(currentProduct);
    });
}


  return { orderNumber, date, total, status, paymentType,breakdown};
}



function getColor(status) {
  if (status === "PAID") {
    return "green";
  } else if (status === "In Progress") {
    return "orange";
  } else {
    return "red";
  }
}

function Row(props) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);
  
  let products = [];
  row.breakdown.forEach((item)=>{
   
  products.push(<OrderSubRow  item={item} descrepshStyle={{fontSize: "1rem"}}
                              subdescrepshStyle={{fontSize: "0.875rem"}}
                              totalStyle={{fontSize: "1rem", color: "black"}} 
                              quantityStyle={{color: "#121212"}}
                />);

  })

  console.log(props.matches)

  return (
    <React.Fragment>
      <TableRow hover={true} onClick={() => setOpen(!open)} selected={open} sx={{ '& > *': { borderBottom: 'unset' }, cursor: "pointer" }}>
      {props.matches && 
        <TableCell component="th" scope="row">
          {/* {row.orderNumber} */}
          {row.orderNumber}
        </TableCell>
      }
        {/* <TableCell >{row.paymentType}</TableCell> */}
        {props.matches && 
        <TableCell align="center" style={{color: getColor(row.status)}}>{row.status} ({row.paymentType})</TableCell>}
        <TableCell align="center">{row.date}</TableCell>
        
        <TableCell align="right">{row.total} KD</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ padding: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
                {!props.matches &&
              <div style={{fontSize: "1rem", marginTop: 20, fontWeight: "bold"}}>
                Order #{row.orderNumber}
              </div>
                }
              <Table size="small" aria-label="purchases">
                <TableBody>
                <ul className="list-group  cart-items">{products}</ul>
                  {/* {row.breakdown.map((historyRow) => (
                    <TableRow className = "bdRow">
                      <TableCell  className = "bdCell" component="th" scope="row">
                        {historyRow.product}
                      </TableCell>
                      <TableCell className = "bdCell" align="right">{historyRow.amount}</TableCell>
                      <TableCell  className = "bdCell" align="right">
                        {historyRow.amount * historyRow.price}
                      </TableCell>
                    </TableRow>
                  ))} */}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

function timeConverter(UNIX_timestamp){
  var a = new Date(UNIX_timestamp * 1000);
  var months = ['01','02','03','04','05','06','07','08','09','10','11','12'];
  var year = a.getFullYear();
  var month = months[a.getMonth()];
  var date = a.getDate();
  var hour = a.getHours();
  var min = a.getMinutes();
  var sec = a.getSeconds();
  var time = date + '/' + month + '/' + year ;
  return time;
}



export default function OrderTable(props) {
  const matches = useMediaQuery(
    json2mq({
      minWidth: 550,
    }),
  );

  const rows = [
    
  ];
  // console.log(props.history.length)
  if(props.past){
  for (var i = 0; i < Object.keys(props.past).length; i++) { 
    let currentOrder = props.past[i];
    let currentRow = createData(currentOrder)
    rows.push(currentRow);
  }
}
console.log(props)

  return (
    <TableContainer component={Paper} style={{overflow: "hidden"}}>
    <Table stickyHeader  aria-label="collapsible table">
      <TableHead>
        <TableRow>
        {matches && 
          <TableCell className='orderCol'>Order Number</TableCell>}
          {matches && 
          <TableCell align="center"  className='orderCol'>Status</TableCell>}
          <TableCell align="center" className='orderCol'>Date</TableCell>
          <TableCell align="right"  className='orderCol'>Total Price&nbsp;(KD)</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        
        {rows.map((row) => (
          
          <Row  row={row} matches={matches} />
        ))}
      </TableBody>
    </Table>
  </TableContainer>
  );
}