import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Button from '@mui/material/Button';
import { withRouter } from 'react-router-dom';
import LoadingButton from '@mui/lab/LoadingButton';
import NavBar from './NavBar';
import {
  validateLoginSession,
  getUserInfo,
  fetchUserInfo,
  getCartTotal,
  getCart,
  getOrders,
  updateUserProfile,
} from './utils.js';
import './Profile.css';
import DoneIcon from '@mui/icons-material/Done';
import { handleDelete, handleMinus, handlePlus } from './cartFunctions';
import EditIcon from '@mui/icons-material/Edit';
import SignUpValidator from './SignUpValidator';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';

const theme = createTheme({
  status: {
    danger: '#e53e3e',
  },
  palette: {
    primary: {
      main: '#213B61',
      darker: '#053e85',
    },
    neutral: {
      main: '#64748B',
      contrastText: '#fff',
    },
  },
});
class Profile extends React.Component {
  constructor(props) {
    // Required step: always call the parent class' constructor
    super(props);
    this.fieldRef = React.createRef();
    let loggedIn = validateLoginSession();
    if (!loggedIn) {
      this.props.history.push('/login');
    }
    let currentUser = getUserInfo();

    // Set the state directly. Use props if necessary.
    this.state = {
      total: getCartTotal(),
      cart: getCart(),
      loggedIn: loggedIn,
      user: loggedIn ? getUserInfo() : {},
      mobile: window.matchMedia('(max-width: 950px)').matches,
      loading: false,
      firstName: currentUser ? currentUser.firstName : '',
      lastName: currentUser ? currentUser.lastName : '',
      email: currentUser ? currentUser.email : '',
      area: currentUser ? currentUser.area : '',
      block: currentUser ? currentUser.block : '',
      street: currentUser ? currentUser.street : '',
      house: currentUser ? currentUser.house : '',
      jadda: currentUser ? currentUser.jadda : '',
      floor: currentUser ? currentUser.floor : '',
      phone: currentUser ? currentUser.phone : '',
      firstNameError: false,
      lastNameError: false,
      phoneError: false,
      disabled: true,
      success: false,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleEdit = this.handleEdit.bind(this);
    this.handleFieldChange = this.handleFieldChange.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handlePlus = handlePlus.bind(this);
    this.handleMinus = handleMinus.bind(this);
    this.handleDelete = handleDelete.bind(this);
  }

  handleEdit() {
    this.setState({ disabled: false });
  }

  async componentDidMount() {
    let loggedIn = await validateLoginSession();
    if (!loggedIn) {
      this.props.history.push('/login');
    }

    let user = await fetchUserInfo();
    if (user) {
      this.setState((prevState, props) => {
        if (!prevState.loggedIn) {
          return null;
        }
        return { user: user };
      });
    }

    let orders = await getOrders();
  }

  async handleSubmit(event) {
    this.setState({ disabled: true, loading: true });

    let user = {
      id: this.state.user.id,
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      phone: this.state.phone,
      area: this.state.area,
      block: this.state.block,
      street: this.state.street,
      house: this.state.house,
      jadda: this.state.jadda,
      floor: this.state.floor,
    };

    try {
      user = await updateUserProfile(user);
      // update localStorage with new user info
      localStorage.setItem('user', JSON.stringify(user));
      setTimeout(() => {
        this.setState({ loading: false });
        this.setState({ success: true });
      }, 1000);
      setTimeout(() => {
        this.setState({ success: false });
      }, 2000);
    } catch (error) {
      console.log(error);
      alert(error);
    }
  }

  handleChange(e) {
    // update state. Validate only if there was an error previously
    this.setState((prevState) => {
      var isValid = true;
      var prevError = prevState[e.target.name + 'Error'];

      if (prevError) {
        if (e.target.name === 'confirm') {
          isValid = SignUpValidator.validate(e.target.name, e.target.value, prevState.password);
        } else {
          isValid = SignUpValidator.validate(e.target.name, e.target.value);
        }
      }

      return {
        ...prevState,
        [e.target.name]: e.target.value,
        [e.target.name + 'Error']: !isValid,
      };
    });
  }

  handleFieldChange(e) {
    // update state and validate
    this.setState((prevState) => {
      var isValid = false;
      if (e.target.name === 'confirm') {
        isValid = SignUpValidator.validate(e.target.name, e.target.value, prevState.password);
      } else {
        isValid = SignUpValidator.validate(e.target.name, e.target.value);
      }

      return {
        ...prevState,
        [e.target.name]: e.target.value,
        [e.target.name + 'Error']: !isValid,
      };
    });
  }

  get_product_key(sel) {
    let flavor = '';
    if (sel.flavor !== null) {
      flavor = sel.flavor + ' ';
    }
    return sel.type + ' ' + sel.bottle + ' ' + flavor + sel.size;
  }

  render() {
    return (
      <div className="">
        <NavBar
          loggedIn={this.state.loggedIn}
          name={this.state.user ? this.state.user.firstName : {}}
          total={this.state.total}
          cart={this.state.cart}
          checkout={false}
          plus={this.handlePlus}
          minus={this.handleMinus}
          delete={this.handleDelete}
        />
        <Container
          component="checkout"
          maxWidth="false"
          className=" pl-0 pr-0 toShrink "
          sx={{ width: 'min(60%, 1140px)', marginTop: 0 }}
        >
          <div className="row main-card" style={{ marginLeft: 'inherit', marginRight: 'inherit' }}>
            {/* <img className="signUpImage col-6" src="./signup-art.svg" /> */}
            <div className="px-5 hundos toExpand" style={{ paddingBottom: '4rem' }}>
              <CssBaseline />
              <Box className="hundos" sx={{ display: 'flex' }}>
                <Box
                  className="mainBlock"
                  sx={{
                    marginTop: 0,
                    display: 'flex',
                    flexDirection: 'column',

                    alignItems: 'center',
                  }}
                >
                  <h1 style={{ width: '100%', marginLeft: 5, marginBottom: 50 }}> User Info</h1>
                  <Box component="form" noValidate sx={{ mt: 3 }}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          autoComplete="given-name"
                          name="firstName"
                          required
                          disabled={this.state.disabled}
                          fullWidth
                          id="firstName"
                          label="First Name"
                          ref={this.fieldRef}
                          onBlur={this.handleFieldChange}
                          onChange={this.handleChange}
                          error={this.state.firstNameError}
                          helperText={this.state.firstNameError ? 'Please enter your first name' : ''}
                          defaultValue={this.state.firstName}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          required
                          fullWidth
                          ref={this.fieldRef}
                          disabled={this.state.disabled}
                          id="lastName"
                          label="Last Name"
                          name="lastName"
                          onBlur={this.handleFieldChange}
                          onChange={this.handleChange}
                          error={this.state.lastNameError}
                          helperText={this.state.lastNameError ? 'Please enter your last name' : ''}
                          autoComplete="family-name"
                          defaultValue={this.state.lastName}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          required
                          fullWidth
                          disabled={true}
                          ref={this.fieldRef}
                          id="email"
                          label="Email Address"
                          name="email"
                          defaultValue={this.state.email}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          required
                          fullWidth
                          disabled={this.state.disabled}
                          ref={this.fieldRef}
                          name="phone"
                          label="Phone Number"
                          type="text"
                          id="phone"
                          onBlur={this.handleFieldChange}
                          onChange={this.handleChange}
                          helperText={this.state.phoneError ? 'Phone number must be between 7 and 14 digits long' : ''}
                          error={this.state.phoneError}
                          autoComplete="phone"
                          defaultValue={this.state.phone}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          required
                          fullWidth
                          disabled={this.state.disabled}
                          ref={this.fieldRef}
                          id="area"
                          label="Area"
                          name="area"
                          onChange={this.handleChange}
                          autoComplete="area"
                          defaultValue={this.state.area}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          required
                          fullWidth
                          disabled={this.state.disabled}
                          ref={this.fieldRef}
                          id="street"
                          label="Street"
                          name="street"
                          onChange={this.handleChange}
                          autoComplete="street"
                          defaultValue={this.state.street}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          autoComplete="block"
                          name="block"
                          required
                          fullWidth
                          ref={this.fieldRef}
                          disabled={this.state.disabled}
                          id="block"
                          label="Block"
                          onChange={this.handleChange}
                          defaultValue={this.state.block}
                        />
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <TextField
                          required
                          fullWidth
                          disabled={this.state.disabled}
                          ref={this.fieldRef}
                          name="house"
                          label="House"
                          id="house"
                          onChange={this.handleChange}
                          autoComplete="house"
                          defaultValue={this.state.house}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          fullWidth
                          disabled={this.state.disabled}
                          ref={this.fieldRef}
                          name="jadda"
                          label="Jadda (Optional)"
                          id="jadda"
                          onChange={this.handleChange}
                          autoComplete="jadda"
                          defaultValue={this.state.jadda}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          fullWidth
                          ref={this.fieldRef}
                          disabled={this.state.disabled}
                          name="floor"
                          label="Floor (Optional)"
                          id="floor"
                          onChange={this.handleChange}
                          autoComplete="floor"
                          defaultValue={this.state.floor}
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <ThemeProvider theme={theme}>
                          {!this.state.success && this.state.disabled && !this.state.loading && (
                            <Button fullWidth variant="contained" onClick={this.handleEdit} sx={{ width: '100%' }}>
                              <EditIcon />
                              Edit
                            </Button>
                          )}

                          {!this.state.success && (!this.state.disabled || this.state.loading) && (
                            <LoadingButton
                              // type="submit"
                              fullWidth
                              variant="contained"
                              onClick={this.handleSubmit}
                              loading={this.state.loading}
                              sx={{ width: '100%' }}
                            >
                              <DoneIcon />
                              {this.state.loading ? '' : 'Done'}
                            </LoadingButton>
                          )}

                          {this.state.success && (
                            <Button fullWidth variant="contained" disabled sx={{ width: '100%' }}>
                              <ThumbUpIcon />
                              Success
                            </Button>
                          )}
                        </ThemeProvider>
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
              </Box>
            </div>
          </div>
        </Container>
      </div>
    );
  }
}
export default withRouter(Profile);
