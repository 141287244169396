import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import LoadingButton from '@mui/lab/LoadingButton';
import './Checkout.css';
import NavBar from './NavBar';
import PaymentIcon from '@mui/icons-material/Payment';
import Button from '@mui/material/Button';
import CartRow from './CartRow';
import getPaymentUrl from './payment';

import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { validateLoginSession, getUserInfo, fetchUserInfo, calcCartTotal } from './utils.js';
import { handleDelete, handleMinus, handlePlus } from './cartFunctions';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import SignUpValidator from './SignUpValidator';
import EmailVerificationModal from './EmailVerificationModal';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import moment from 'moment';
import { FormControl, InputLabel, MenuItem, Select, Stack } from '@mui/material';


const theme = createTheme({
    status: {
        danger: '#e53e3e',
    },
    palette: {
        primary: {
            main: '#213B61',
            darker: '#053e85',
        },
        neutral: {
            main: '#64748B',
            contrastText: '#fff',
        },
    },
});

const DELIVERY_FEE = 1; // KWD

let currentUser = getUserInfo();
class Checkout extends React.Component {
    constructor(props) {
        // Required step: always call the parent class' constructor
        super(props);
        let loggedIn = validateLoginSession();
        let cached_cart = localStorage.getItem('cart') ? JSON.parse(localStorage.getItem('cart')) : [];

        // Set the state directly. Use props if necessary.
        this.state = {
            total: calcCartTotal(cached_cart),
            cart: cached_cart,
            phase: true,
            emailModalOpen: false,
            loggedIn: loggedIn,
            user: loggedIn ? getUserInfo() : {},

            mobile: window.matchMedia('(max-width: 950px)').matches,
            loading: false,
            firstName: currentUser ? currentUser.firstName : '',
            lastName: currentUser ? currentUser.lastName : '',
            email: currentUser ? currentUser.email : '',
            area: currentUser ? currentUser.area : '',
            block: currentUser ? currentUser.block : '',
            street: currentUser ? currentUser.street : '',
            house: currentUser ? currentUser.house : '',
            jadda: currentUser ? currentUser.jadda : '',
            floor: currentUser ? currentUser.floor : '',
            phone: currentUser ? currentUser.phone : '',
            paymentMethod: '',
            deliveryDate: null,
            firstNameError: false,
            deliveryDateError: false,
            lastNameError: false,
            emailError: false,
            phoneError: false,
            areaError: false,
            blockError: false,
            streetError: false,
            houseError: false,
            paymentMethodError: false,
            emailHelperText: 'Please enter a valid email address',
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleFieldChange = this.handleFieldChange.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleAddress = this.handleAddress.bind(this);
        this.toggleModal = this.toggleModal.bind(this);
        this.handlePlus = handlePlus.bind(this);
        this.handleMinus = handleMinus.bind(this);
        this.handleDelete = handleDelete.bind(this);
        this.handleDeliveryDateChange = this.handleDeliveryDateChange.bind(this);
        this.disableFridays = this.disableFridays.bind(this);
    }

    disableFridays(date) {
        return date.day() === 5;
    }

    async handleSubmit(event) {
        event.preventDefault();

        // validate all fields
        let isValid = true;
        const errors = {};

        for (let key in this.state) {
            if (key.includes('Error')) {

                // check deliveryDate error
                if (key === 'deliveryDateError') {
                    if (this.state.deliveryDate === null) {
                        errors[key] = true;
                        isValid = false;
                    } else {
                        errors[key] = false;
                    }
                    continue;
                }
                let newKey = key.replace('Error', '');
                errors[key] = !SignUpValidator.validate(newKey, this.state[newKey]);
                if (errors[key]) {
                    isValid = false;
                }
            }
        }

        this.setState(errors);

        if (!isValid) {
            return;
        }

        this.setState({ loading: true });

        // get all needed params from state
        let params = { ...this.state };

        const products = params.cart;
        const productsArray = [];
        for (let i = 0; i < products.length; i++) {
            const product = products[i];
            const productObj = {
                Type: product.type,
                'Bottle Type': product.bottle,
                Flavor: product.flavor,
                Size: product.size,
                Quantity: product.quantity,
                Price: product.unitPrice,
            };
            productsArray.push(productObj);
        }
        params.products = productsArray;

        //remove unneeded params
        delete params.cart;
        delete params.phase;
        delete params.loading;
        delete params.loggedIn;
        delete params.mobile;
        delete params.firstNameError;
        delete params.lastNameError;
        delete params.paymentMethodError;
        delete params.emailError;
        delete params.phoneError;
        delete params.emailTaken;
        delete params.emailHelperText;
        delete params.deliveryDateError;

        let timestamp = Date.now();
        params.timestamp = timestamp;
        params.total += DELIVERY_FEE;

        try {
            let url = await getPaymentUrl(params);
            window.location.href = url;
        } catch (error) {
            if (error.message === 'Email not verified') {
                this.setState({ emailModalOpen: true, loading: false });
            } else {
                this.setState({ loading: false });
                console.log(error.message);
                alert(error.message);
            }
        }
    }

    handleDeliveryDateChange(date) {
        this.setState({ deliveryDate: moment(date) });
    }

    handleChange(e) {
        // update state. Validate only if there was an error previously
        this.setState((prevState) => {
            var isValid = true;
            var prevError = prevState[e.target.name + 'Error'];
            var prevEmailHelperText = prevState.emailHelperText;

            if (prevError) {
                isValid = SignUpValidator.validate(e.target.name, e.target.value);
            }

            // if email is changed, overwite the helper text
            // cuz helper text could've been changed to `email already taken`

            if (e.target.name === 'email') {
                prevEmailHelperText = 'Please enter a valid email address';
            }
            return {
                ...prevState,
                emailHelperText: prevEmailHelperText,
                [e.target.name]: e.target.value,
                [e.target.name + 'Error']: !isValid,
            };
        });
    }

    handleFieldChange(e) {
        // update state and validate
        this.setState((prevState) => {
            const isValid = SignUpValidator.validate(e.target.name, e.target.value);
            return {
                ...prevState,
                [e.target.name]: e.target.value,
                [e.target.name + 'Error']: !isValid,
            };
        });
    }

    handleAddress() {
        this.setState((prevState, props) => {
            return { phase: !prevState.phase };
        });
    }

    toggleModal() {
        this.setState((prevState, props) => {
            return { emailModalOpen: !prevState.emailModalOpen };
        });
    }

    async componentDidMount() {
        // update name (instead of `log in`) if user is logged in
        const handler = (e) => this.setState({ mobile: e.matches });
        window.matchMedia('(max-width: 950px)').addEventListener('change', handler);

        let loggedIn = await validateLoginSession();
        if (loggedIn) {
            let user = await fetchUserInfo();
            if (user) {
                this.setState((prevState, props) => {
                    if (!prevState.loggedIn) {
                        return null;
                    }
                    return { user: user };
                });
            }
        }

        // update cart from cache
        let cached_cart = localStorage.getItem('cart') ? JSON.parse(localStorage.getItem('cart')) : [];
        this.setState({
            total: calcCartTotal(cached_cart),
            cart: cached_cart,
            loggedIn: loggedIn,
            user: loggedIn ? getUserInfo() : {},
        });
    }
    get_product_key(sel) {
        let flavor = '';
        if (sel.flavor !== null) {
            flavor = sel.flavor + ' ';
        }
        return sel.type + ' ' + sel.bottle + ' ' + flavor + sel.size;
    }

    //   console.log(testvalue);
    render() {
        let rows = [];
        this.state.cart.forEach((item) => {
            rows.push(
                <CartRow
                    minus={this.handleMinus}
                    delete={this.handleDelete}
                    plus={this.handlePlus}
                    key={item.key}
                    item={item}
                ></CartRow>
            );
        });

        return (

            <LocalizationProvider dateAdapter={AdapterMoment}>
                <div style={{ background: 'rgb(236 236 236);' }}>
                    <EmailVerificationModal
                        modalOpen={this.state.emailModalOpen}
                        toggleModal={this.toggleModal}
                    ></EmailVerificationModal>
                    <NavBar
                        loggedIn={this.state.loggedIn}
                        name={this.state.user ? this.state.user.firstName : ''}
                        total={this.state.total}
                        cart={this.state.cart}
                        checkout={false}
                        plus={this.handlePlus}
                        minus={this.handleMinus}
                        delete={this.handleDelete}
                    />
                    <Container
                        component="checkout"
                        maxWidth="xl"
                        className=" pl-0 pr-0  , "
                        sx={{ width: 'min(95%, 2500px)', backgroundColor: 'rgb(236 236 236)', borderRadius: '25px' }}
                    >
                        <div className="row main-card">
                            {/* <img className="signUpImage col-6" src="./signup-art.svg" /> */}
                            <div className="px-5 hundos rm-padding1" style={{ paddingBottom: '4rem' }}>
                                <CssBaseline />
                                <Box className="hundos" sx={{ display: 'flex' }}>
                                    {this.state.phase && (
                                        <Box
                                            className="mainBlock hundos rm-padding2"
                                            sx={{
                                                marginTop: 8,
                                                display: 'flex',
                                                flexDirection: 'column',
                                                // maxWidth: "600px",
                                                alignItems: 'center',
                                            }}
                                        >
                                            <h3 className="modal-title">Cart</h3>
                                            <div className="modal-body">
                                                {rows.length === 0 && <div id="empty-text">Your cart is empty.</div>}
                                                <ul className="list-group cart-list cart-items">{rows}</ul>
                                            </div>
                                            <div className="modal-footer footie" style={{ width: '100%' }}>
                                                {rows.length > 0 && (
                                                    <React.Fragment>
                                                        <span className="checkoutTxt">
                                                            Delivery Fee
                                                            <div style={{ marginLeft: 'auto' }}>
                                                                1 KD<span className="cart_total"></span>
                                                            </div>
                                                        </span>
                                                        <span className="checkoutTxt ml-0" style={{ fontWeight: "bold" }}>
                                                            Total
                                                            <div style={{ marginLeft: 'auto' }}>
                                                                {this.state.total + DELIVERY_FEE} KD<span className="cart_total"></span>
                                                            </div>
                                                        </span>
                                                    </React.Fragment>
                                                    // conditionally render the checkout button if the other box component is not rendered
                                                )}

                                                {rows.length > 0 && this.state.phase && this.state.mobile && (
                                                    <ThemeProvider theme={theme}>
                                                        <Button variant="contained" onClick={this.handleAddress} style={{ width: '84%', height: 50, marginTop: 20, clear: 'both' }}>
                                                            <NavigateNextIcon /> &nbsp;Continue
                                                        </Button>
                                                    </ThemeProvider>
                                                )}
                                            </div>
                                        </Box>
                                    )}
                                    {(!this.state.phase || !this.state.mobile) && (
                                        <Box
                                            className="mainBlock"
                                            sx={{
                                                marginTop: 8,
                                                display: 'flex',
                                                flexDirection: 'column',
                                                maxWidth: '600px',
                                                alignItems: 'center',
                                            }}
                                        >
                                            <h3 className="modal-title">User Info</h3>
                                            <Box component="form" noValidate sx={{ mt: 3 }}>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12} sm={6}>
                                                        <TextField
                                                            autoComplete="given-name"
                                                            name="firstName"
                                                            required
                                                            fullWidth
                                                            id="firstName"
                                                            label="First Name"
                                                            onBlur={this.handleFieldChange}
                                                            onChange={this.handleChange}
                                                            error={this.state.firstNameError}
                                                            defaultValue={this.state.firstName}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} sm={6}>
                                                        <TextField
                                                            required
                                                            fullWidth
                                                            id="lastName"
                                                            label="Last Name"
                                                            name="lastName"
                                                            onBlur={this.handleFieldChange}
                                                            onChange={this.handleChange}
                                                            error={this.state.lastNameError}
                                                            autoComplete="family-name"
                                                            defaultValue={this.state.lastName}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <TextField
                                                            required
                                                            fullWidth
                                                            id="email"
                                                            label="Email Address"
                                                            name="email"
                                                            onBlur={this.handleFieldChange}
                                                            onChange={this.handleChange}
                                                            error={this.state.emailError}
                                                            helperText={this.state.emailError ? this.state.emailHelperText : ''}
                                                            autoComplete="email"
                                                            defaultValue={this.state.email}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <TextField
                                                            required
                                                            fullWidth
                                                            name="phone"
                                                            label="Phone Number"
                                                            type="text"
                                                            id="phone"
                                                            onBlur={this.handleFieldChange}
                                                            onChange={this.handleChange}
                                                            helperText={this.state.phoneError ? 'Phone number must be between 7 and 14 digits long' : ''}
                                                            error={this.state.phoneError}
                                                            autoComplete="phone"
                                                            defaultValue={this.state.phone}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <TextField
                                                            required
                                                            fullWidth
                                                            id="area"
                                                            label="Area"
                                                            name="area"
                                                            error={this.state.areaError}
                                                            onChange={this.handleChange}
                                                            autoComplete="area"
                                                            defaultValue={this.state.area}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <TextField
                                                            required
                                                            fullWidth
                                                            id="street"
                                                            label="Street"
                                                            name="street"
                                                            error={this.state.streetError}
                                                            onChange={this.handleChange}
                                                            autoComplete="street"
                                                            defaultValue={this.state.street}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} sm={6}>
                                                        <TextField
                                                            autoComplete="block"
                                                            name="block"
                                                            required
                                                            fullWidth
                                                            id="block"
                                                            label="Block"
                                                            error={this.state.blockError}
                                                            onChange={this.handleChange}
                                                            defaultValue={this.state.block}
                                                        />
                                                    </Grid>

                                                    <Grid item xs={12} sm={6}>
                                                        <TextField
                                                            required
                                                            fullWidth
                                                            name="house"
                                                            label="House"
                                                            id="house"
                                                            error={this.state.houseError}
                                                            onChange={this.handleChange}
                                                            autoComplete="house"
                                                            defaultValue={this.state.house}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} sm={6}>
                                                        <TextField
                                                            fullWidth
                                                            name="jadda"
                                                            label="Jadda (Optional)"
                                                            id="jadda"
                                                            onChange={this.handleChange}
                                                            autoComplete="jadda"
                                                            defaultValue={this.state.jadda}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} sm={6}>
                                                        <TextField
                                                            fullWidth
                                                            name="floor"
                                                            label="Floor (Optional)"
                                                            id="floor"
                                                            onChange={this.handleChange}
                                                            autoComplete="floor"
                                                            defaultValue={this.state.floor}
                                                        />
                                                    </Grid>

                                                    <Grid item xs={12}>
                                                        {this.state.mobile ? (
                                                            <Stack>
                                                                <MobileDatePicker
                                                                    label="Delivery Date"
                                                                    required
                                                                    inputFormat="DD/MM/yyyy"
                                                                    value={this.state.deliveryDate}
                                                                    minDate={moment(new Date()).add(1, 'd')}
                                                                    onChange={this.handleDeliveryDateChange}
                                                                    renderInput={(params) => <TextField
                                                                        {...params}
                                                                        error={this.state.deliveryDateError}
                                                                    />}
                                                                    shouldDisableDate={this.disableFridays}
                                                                />
                                                            </Stack>
                                                        ) : (
                                                            <Stack>
                                                                <DesktopDatePicker
                                                                    label="Delivery Date"
                                                                    required
                                                                    inputFormat="DD/MM/yyyy"
                                                                    value={this.state.deliveryDate}
                                                                    minDate={moment(new Date()).add(1, 'd')}
                                                                    onChange={this.handleDeliveryDateChange}
                                                                    renderInput={(params) => <TextField
                                                                        {...params}
                                                                        error={this.state.deliveryDateError}
                                                                    />}
                                                                    shouldDisableDate={this.disableFridays}
                                                                />
                                                            </Stack>
                                                        )}
                                                            <FormControl fullWidth style={{marginTop: '20px'}}>
                                                                <InputLabel id="paymentMethodSelect">Payment Method</InputLabel>
                                                                <Select
                                                                    value={this.state.paymentMethod}
                                                                    label="Payment Method"
                                                                    name="paymentMethod"
                                                                    id="paymentMethodSelect"
                                                                    fullWidth
                                                                    required
                                                                    onChange={this.handleChange}
                                                                    error={this.state.paymentMethodError}
                                                                >

                                                                    <MenuItem value={"KNET"}>KNET</MenuItem>
                                                                    <MenuItem value={"VISA/MASTERCARD"}>Visa/Mastercard</MenuItem>
                                                                </Select>
                                                            </FormControl>
                                                        {this.state.mobile && (
                                                            <ThemeProvider theme={theme}>
                                                                <Button
                                                                    variant="outlined"
                                                                    onClick={this.handleAddress}
                                                                    style={{ width: '100%', clear: 'both', marginTop: '20px' }}
                                                                >
                                                                    <ShoppingCartIcon /> &nbsp;Back to Cart
                                                                </Button>
                                                            </ThemeProvider>
                                                        )}

                                                        <ThemeProvider theme={theme}>
                                                            {/* <Button variant="contained" onClick={this.handleSubmit} style={{ width: '100%' }}>
                              <PaymentIcon /> &nbsp;Payment
                            </Button> */}
                                                            <LoadingButton
                                                                type="submit"
                                                                fullWidth
                                                                loading={this.state.loading}
                                                                variant="contained"
                                                                onClick={this.handleSubmit}
                                                                sx={{ width: '100%', marginTop: '20px' }}
                                                            >
                                                                <PaymentIcon /> &nbsp;Submit
                                                            </LoadingButton>
                                                        </ThemeProvider>
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                        </Box>
                                    )}
                                </Box>
                            </div>
                        </div>
                    </Container>
                </div>
            </LocalizationProvider>
        );
    }
}

export default Checkout;
