import * as React from 'react';
import { withRouter } from 'react-router-dom';
import NavBar from './NavBar';
import CircularProgress from '@mui/material/CircularProgress';
import { validateLoginSession, getUserInfo, fetchUserInfo, getCartTotal, getCart, getOrders } from './utils.js';
import CartRow from './CartRow';
import './Orders.css';
import { handleDelete, handleMinus, handlePlus } from './cartFunctions';
import OrderTable from './OrderTable';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import ColoredCircularProgress from './ColoredCircularProgress';

class Orders extends React.Component {
  constructor(props) {
    // Required step: always call the parent class' constructor
    super(props);
    let loggedIn = validateLoginSession();
    if (!loggedIn) {
      this.props.history.push('/login');
    }

    // Set the state directly. Use props if necessary.
    this.state = {
      total: getCartTotal(),
      cart: getCart(),
      loggedIn: loggedIn,
      loading: true,
      user: loggedIn ? getUserInfo() : {},
      past: {},
    };
    this.handlePlus = handlePlus.bind(this);
    this.handleMinus = handleMinus.bind(this);
    this.handleDelete = handleDelete.bind(this);
  }

  async componentDidMount() {
    // update name (instead of `log in`) if user is logged in
    // get timestamp now as string

    let user = await fetchUserInfo();
    let loggedIn = await validateLoginSession();
    this.setState({loggedIn: loggedIn})

    if (user) {
      this.setState((prevState, props) => {
        if (!prevState.loggedIn) {
          return null;
        }
        return { user: user };
      });
    }

    let orders = await getOrders();
    this.setState({ past: { ...orders }, loading: false });
    // console.log(this.state.history)
  }

  render() {

    return (
      <div className="">
        <NavBar
          loggedIn={this.state.loggedIn}
          name={this.state.user ? this.state.user.firstName : ''}
          total={this.state.total}
          cart={this.state.cart}
          checkout={false}
          plus={this.handlePlus}
          minus={this.handleMinus}
          delete={this.handleDelete}
        />
        <div className="d-flex align-items-center flex-column" style={{ marginTop: 50 }}>
          <div style={{ width: '100%', maxWidth: '1000px', minHeight: '500px' }}>
            <h1 style={{ width: '90%', marginLeft: 5 }}> Order History</h1>
            <div style={{ width: '100%', height: '100%', marginTop: '30px', marginBottom: '40px', borderRadius: '10px' }}>
              {this.state.loading && (
                <div
                  style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', minHeight: 400 }}
                >
                  <ColoredCircularProgress />
                </div>
              )}

              {Object.keys(this.state.past).length > 0 && !this.state.loading && <OrderTable past={this.state.past} />}

              {Object.keys(this.state.past).length == 0 && !this.state.loading && (
                <h4>You don't have any pending or past orders.</h4>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Orders);
