import React, { Component } from "react";
import anime from "animejs";

export class GreatThings extends Component {
  componentDidMount() {
    anime
      .timeline({ loop: false })
      .add({
        targets: ".ml3 .letter",
        opacity: [0, 1],
        easing: "easeInOutQuad",
        duration: 1000,
        delay: (el, i) => 10 * (i + 1) + 100
      })
  }

  render() {
    return <h1 className={this.props.classes + " ml3"} id={this.props.id}>
    {this.props.text.split("").map((v, index) => (
      <span className="letter" key={index}>{v}</span>
    ))}
  </h1>
  }
}