async function getPaymentUrl(params) {
  let reqJson = {
    amount: params.total,
    phone: params.phone,
    timestamp: params.timestamp,
    redirectBase: process.env.REACT_APP_HOST,
    products: params.products,
    userId: params.user ? params.user.id : null,
    firstName: params.firstName,
    lastName: params.lastName,
    email: params.email,
    area: params.area,
    block: params.block,
    street: params.street,
    jadda: params.jadda,
    house: params.house,
    floor: params.floor,
    paymentMethod: params.paymentMethod,
    deliveryDate: params.deliveryDate.format('DD-MM-YYYY'),
  };
  let res = await fetch(process.env.REACT_APP_HOST + '/v1/payment/', {
    method: 'POST',
    body: JSON.stringify(reqJson),
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
  });

  if (res.ok) {
    let response = await res.json();
    return response.url;
  } else if (res.status === 401) {
    let err = await res.json();
    if (err.message === 'Email not verified') {
      throw new Error(err.message);
    }
  } else {
    throw new Error(`Failed to get payment url: ${res.statusText}`);
  }
}
// get timestamp

export default getPaymentUrl;
