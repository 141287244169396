import * as React from 'react';
import './Loading.css';

class Loading extends React.Component {
  render() {
    return (
      <div className="loading">
        <svg className="svg" width="100px" height="100px" viewBox="-50 -50 100 100">
          <circle cx="0" cy="0" r="30" />
        </svg>
      </div>
    );
  }
}

export default Loading;
