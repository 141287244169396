import NavCart from './NavCart';
import Cart from './Cart.js';
import React from 'react';
import Box from '@mui/material/Box';
import MenuIcon from '@mui/icons-material/Menu';
import Drawer from '@mui/material/Drawer';
import LanguageIcon from '@mui/icons-material/Language';
import List from '@mui/material/List';
import HomeIcon from '@mui/icons-material/Home';
import LoginIcon from '@mui/icons-material/Login';
import LogoutIcon from '@mui/icons-material/Logout';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ReceiptIcon from '@mui/icons-material/Receipt';
import { withRouter } from 'react-router-dom';
import { logout } from './utils';
import './Main.css';
import Login from './Login';
import AccountCircle from '@mui/icons-material/AccountCircle';


class NavBar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loginModalOpen: props.location.pathname === '/login',
            drawerOpen: false,
            show: false,
        };

        this.toggleModal = this.toggleModal.bind(this);
        this.toggleDrawer = this.toggleDrawer.bind(this);
        this.handleLogout = this.handleLogout.bind(this);
        this.controlNavbarVisiblity = this.controlNavbarVisiblity.bind(this);
        this.body = document.getElementById("body");

    }


    toggleLanguage() { }

    toggleModal() {
        this.setState((prevState, props) => {
            return { loginModalOpen: !prevState.loginModalOpen }
        })
    }

    handleLogout() {
        logout();
    }

    toggleDrawer(event) {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        this.setState((prevState, props) => {
            return { drawerOpen: !prevState.drawerOpen }
        })
    };


    get_quantity_from_cart() {
        let cart = localStorage.getItem('cart');
        if (!cart) {
            return 0;
        }
        cart = JSON.parse(cart);

        let quantity = 0;
        cart.forEach((item) => {
            quantity += item.quantity;
        });
        return quantity;
    }

    controlNavbarVisiblity() {
        if (this.body.scrollTop > 100) {
            this.setState({ show: true });
        } else {
            this.setState({ show: false });
        }
    }

    componentDidMount() {
        this.body.addEventListener('scroll', this.controlNavbarVisiblity);
    }

    componentWillUnmount() {
        this.body.removeEventListener('scroll', this.controlNavbarVisiblity);
    }


    render() {

        let quantity = this.get_quantity_from_cart()
        return (
            <React.Fragment>
                <div id="navbar" className='navbar' style={{height: 100, position: "relative"}}></div>
                <nav className={`navbar ${this.state.show && 'navbar-white'}`} style={{zIndex: 2}}>
                    <span style={{ fontSize: "50px" }}>
                        <MenuIcon onClick={this.toggleDrawer} fontSize="inherit" sx={{ color: 'rgb(33, 59, 97)' }} />
                    </span>

                    {!this.props.checkout && <NavCart count={quantity}></NavCart>}
                    <Drawer
                        open={this.state.drawerOpen}
                        onClose={this.toggleDrawer}
                    >
                        <Box
                            sx={{ width: 250, color: "rgb(33, 59, 97)" }}
                            role="presentation"
                            onClick={this.toggleDrawer}
                            onKeyDown={this.toggleDrawer}
                        >
                            <List sx={{ backgroundColor: "white" }}>
                                <ListItem key={4} disablePadding>
                                    <ListItemButton onClick={() => { this.props.history.push('/') }}>
                                        <ListItemIcon>
                                            <HomeIcon sx={{ color: 'rgb(33, 59, 97)' }} />
                                        </ListItemIcon>
                                        <ListItemText primary={'Home'} />
                                    </ListItemButton>
                                </ListItem>
                                {this.props.loggedIn ?
                                    <ListItem key={0} disablePadding>
                                        <ListItemButton onClick={this.handleLogout}>
                                            <ListItemIcon>
                                                <LogoutIcon sx={{ color: 'rgb(33, 59, 97)' }} />
                                            </ListItemIcon>
                                            <ListItemText primary={'Logout'} />
                                        </ListItemButton>
                                    </ListItem> :
                                    <ListItem key={1} disablePadding>
                                        <ListItemButton onClick={this.toggleModal}>
                                            <ListItemIcon>
                                                <LoginIcon sx={{ color: 'rgb(33, 59, 97)' }} />
                                            </ListItemIcon>
                                            <ListItemText primary={'Login'} />
                                        </ListItemButton>
                                    </ListItem>
                                }
                                {this.props.loggedIn &&
                                    <React.Fragment>
                                        <ListItem key={2} disablePadding>
                                            <ListItemButton onClick={() => { this.props.history.push('/user/profile') }}>
                                                <ListItemIcon>
                                                    <AccountCircle sx={{ color: 'rgb(33, 59, 97)' }} />
                                                </ListItemIcon>
                                                <ListItemText primary={'Account Management'} />
                                            </ListItemButton>
                                        </ListItem>
                                        <ListItem key={3} disablePadding>
                                            <ListItemButton onClick={() => { this.props.history.push('/user/orders') }}>
                                                <ListItemIcon>
                                                    <ReceiptIcon sx={{ color: 'rgb(33, 59, 97)' }} />
                                                </ListItemIcon>
                                                <ListItemText primary={'Order History'} />
                                            </ListItemButton>
                                        </ListItem>
                                    </React.Fragment>}
                                <ListItem key={1} disablePadding>
                                    <ListItemButton>
                                        <ListItemIcon>
                                            <LanguageIcon sx={{ color: 'rgb(33, 59, 97)' }} />
                                        </ListItemIcon>
                                        <ListItemText primary={'عربي'} />
                                    </ListItemButton>
                                </ListItem>
                            </List>
                        </Box>
                    </Drawer>

                </nav>
                {!this.props.checkout && (
                    <Cart plus={this.props.plus} minus={this.props.minus} delete={this.props.delete} cart={this.props.cart} toggleLoginModal={this.toggleModal} loggedIn={this.props.loggedIn} total={this.props.total}></Cart>
                )}
                <Login count={this.props.count} open={this.state.loginModalOpen} toggleModal={this.toggleModal}></Login>

            </React.Fragment>
        );
    }
}
export default withRouter(NavBar);
