import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { resendVerificationEmail } from './utils';
import LoadingButton from '@mui/lab/LoadingButton';

function ResponsiveDialog(props) {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const [loading, setLoading] = React.useState(false);
    const [sent, setSent] = React.useState(false);

    async function resendEmail() {
        setLoading(true);
        await resendVerificationEmail();
        setLoading(false);
        setSent(true);
    }

    return (
        <div>


            <Dialog
                fullScreen={fullScreen}
                open={props.modalOpen}
                onClose={props.toggleModal}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle id="responsive-dialog-title">
                    {sent ? "Verification email sent" : "Please verify your email address"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {sent ? "Please check your email and click the link to verify your email address." : 
                        "You will need to verify your email address before you can make purchases."}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={props.toggleModal} sx={{ color: "#435C82" }}>
                        Close
                    </Button>
                    <LoadingButton loading={loading} onClick={resendEmail} autoFocus sx={{ color: "#435C82", '& .MuiLoadingButton-loadingIndicator': {
                        color: "#435C82",
                      }}}>
                        Resend Email
                    </LoadingButton>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default ResponsiveDialog;