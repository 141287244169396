import React from 'react';

class ProductButton extends React.Component {
    constructor(props) {
        super(props);
        this.handleClick = this.handleClick.bind(this)
    }

    handleClick() {
        if (!('stock' in this.props) || this.props.stock > 0) {
            this.props.handleClick(this.props.category, this.props.text)
        }
    }

    render() {
        let className = "btn product-btn "
        if (this.props.selected === this.props.text) {
            className += "active "
        }
        if (this.props.big) {
            className += "big-btn"
        }
        if (this.props.stock === 0) {
            className += "outOfStock"
        }

        return (
            <button type="button" onClick={this.handleClick}
            className={className}> {this.props.text} </button>
        )

    }

}

export default ProductButton