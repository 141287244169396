import React from 'react';
import Main from './Main';
import SignUp from './SignUp';
import Checkout from './Checkout';
import Profile from './Profile';
import Orders from './Orders';
import OrderConfirmation from './OrderConfirmation';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import EmailVerification from './EmailVerification';
import SignUpConfirmation from './SignUpConfirmation';
import ForgotConfirmation from './ForgotConfirmation';
import ResetPassword from './ResetPassword';

export default function App() {
  return (
    <Router>
      {/* A <Switch> looks through its children <Route>s and
                renders the first one that matches the current URL. */}
      <Switch>
        <Route path="/signup" component={SignUp} />
        <Route path="/signupConfirmation" component={SignUpConfirmation} />
        <Route path="/forgotConfirmation" component={ForgotConfirmation} />
        <Route path="/timeout" component={Main} />
        <Route path="/login" component={Main} />
        <Route path="/reset-password" component={ResetPassword} />
        <Route path="/checkout" component={Checkout} />
        <Route path="/verify-email" component={EmailVerification} />
        <Route path="/user/profile" component={Profile} />
        <Route path="/success" component={OrderConfirmation} />
        <Route path="/failure" component={OrderConfirmation} />
        <Route path="/user/orders" component={Orders} />
        <Route path="/" component={Main} />
      </Switch>
    </Router>
  );
}
